const prefix = 'https://mastitisapi.goigi.me/api/'
// const prefix = 'http://localhost:9090/api/'
export const Constants = {
  adminLogIn : prefix + 'admin/login',
  getAdminDetails : prefix + 'admin',
  registerOrUpdateAdmin: prefix + 'admin/register',
  
  getAllUsers: prefix + 'auth/users',
  getUserById : prefix + 'auth/users/',
  addNewUser : prefix + 'auth/register',
  deleteUser : prefix + 'auth/users/',

  
  getAllOrders : prefix + 'order/all-orders',
  getOrderById : prefix + 'order/',
  changeOrderStatus : prefix + 'order/update-status/',


  getMastitisNews: prefix + 'news',
  getMastitisNewsbyId: prefix + 'news/',
  postMastitisNews: prefix + 'news',
  updateMastitisNews: prefix + 'news/',
  deleteMastitisNews: prefix + 'news/',

  getAlltackleMastitis: prefix + 'tackleMastitis',
  getTackleMastitisByID: prefix + 'tackleMastitis/',
  createTackleMastitis: prefix + 'tackleMastitis',
  deleteTackleMastitis: prefix + 'tackleMastitis/',
  updateTackleMastitis: prefix + 'tackleMastitis/',

  createMastitisHeading: prefix + 'mastitisHeading',
  getAllMastitisHeading: prefix + 'mastitisHeading',
  getMastitisHeadingById: prefix + 'mastitisHeading/',
  deleteMastitisHeading: prefix + 'mastitisHeading/',
  updateMastitisHeading: prefix + 'mastitisHeading/',

  createAndUpdateSocialLinks: prefix + 'social-links',
  getAllSocialLinks: prefix + 'social-links',

  createNewSubscriptions: prefix + 'subscriptons/add',
  getAllSubscriptions: prefix + 'subscriptons/all',
  getAllSubscriptionsById: prefix + 'subscriptons/',
  updateSubscription: prefix + 'subscriptons/update/',
  deleteSubscription: prefix + 'subscriptons/delete/',

  addProduct: prefix + 'product/create',
  getAllProduct: prefix + 'product/all',
  getProductById: prefix + 'product/details/',
  updateProduct: prefix + 'product/update/',
  deleteProduct: prefix + 'product/delete/',

  addOrUpdateAboutUs: prefix + 'about-us/addOrUpdate',
  getAboutUs: prefix + 'about-us/get',

  addOrUpdatePolicy: prefix + 'policy-service',
  getpolicy: prefix + 'policy-service',

  uploadOrUpdateTrainingData : prefix+ 'traincentre/upload-or-update',
  getTrainingData: prefix + 'traincentre/get',

  getCalculationData : prefix + 'calculation',
  uploadOrUpdateCalculationdata: prefix + 'calculation',
}
