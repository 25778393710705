import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Button, Modal, Form, Table } from 'react-bootstrap'
import { FaRegEdit, FaTrashAlt } from 'react-icons/fa'
import { Constants } from 'src/Constants'
import Swal from 'sweetalert2'

const TackleMastitis = () => {
  const [tackleData, setTackleData] = useState([]) // You can keep it for future use

  // Static product items
  const [newTackleData, setNewTackleData] = useState({
    heading: '',
    description: '',
    image: null,
  })

  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage] = useState(5)
  useEffect(() => {
    fetchTackleData()
  }, [])

  const fetchTackleData = async () => {
    try {
      const response = await fetch(Constants.getAlltackleMastitis)
      const data = await response.json()
      setTackleData(data)
    } catch (error) {
      console.error('Error fetching tackle mastitis data:', error)
    }
  }

  const [showAddModal, setShowAddModal] = useState(false) // Add modal state

  const handleAddClose = () => setShowAddModal(false)
  const handleAddShow = () => setShowAddModal(true)

  const handleAddSubmit = async () => {
    try {
      // Check if any required field is empty
      if (!newTackleData.heading || !newTackleData.description || !newTackleData.image) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Please fill in all required fields: Heading, Description, and Image.',
        })
        return
      }
      const formData = new FormData()
      formData.append('heading', newTackleData.heading)
      formData.append('description', newTackleData.description)
      formData.append('image', newTackleData.image)

      const response = await fetch(Constants.createTackleMastitis, {
        method: 'POST',
        body: formData,
      })

      if (response.ok) {
        Swal.fire({
          icon: 'success',
          title: 'Success!',
          text: 'Tackle Mastitis added successfully',
        });
        handleAddClose();
        fetchTackleData(); // Refresh the data after adding
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Failed to add Tackle Mastitis',
        });
      }
    } catch (error) {
      console.error('Error adding tackle mastitis:', error)
    }
  }

  const handleFileChange = (e) => {
    setNewTackleData({
      ...newTackleData,
      image: e.target.files[0],
    })
  }
  const handleDelete = async (id) => {
    try {
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: 'You won\'t be able to revert this!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      });
  
      if (result.isConfirmed) {
        const response = await fetch(Constants.deleteTackleMastitis + id, {
          method: 'DELETE',
        });
  
        if (response.ok) {
          Swal.fire({
            icon: 'success',
            title: 'Success!',
            text: 'Tackle Mastitis deleted successfully',
          });
          fetchTackleData(); // Refresh the data after deleting
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Failed to delete Tackle Mastitis',
          });
        }
      }
    } catch (error) {
      console.error('Error deleting tackle mastitis:', error);
    }
  };
  

  const [showEditModal, setShowEditModal] = useState(false) // Edit modal state
  const [editTackleData, setEditTackleData] = useState({
    id: '',
    heading: '',
    description: '',
    image: null,
  })

  const handleEditClose = () => setShowEditModal(false)
  const handleEditShow = () => setShowEditModal(true)

  const handleEditSubmit = async () => {
    try {
      // Check if any required field is empty
      if (!editTackleData.heading || !editTackleData.description || !editTackleData.image) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Please fill in all required fields: Heading , Description and Image.',
        });
        return;
      }
      const formData = new FormData()
      formData.append('heading', editTackleData.heading)
      formData.append('description', editTackleData.description)
      formData.append('image', editTackleData.image)

      const response = await fetch(Constants.updateTackleMastitis + editTackleData.id, {
        method: 'PUT',
        body: formData,
      })

      if (response.ok) {
        Swal.fire({
          icon: 'success',
          title: 'Success!',
          text: 'Tackle Mastitis updated successfully',
        });
        handleEditClose()
        fetchTackleData() // Refresh the data after updating
      } else {
        alert('Failed to update Tackle Mastitis')
      }
    } catch (error) {
      console.error('Error updating tackle mastitis:', error)
    }
  }

  const handleEditClick = (id, heading, description) => {
    setEditTackleData({
      id,
      heading,
      description,
      image: null, // Initialize to null, you may want to fetch the existing image if needed
    })
    handleEditShow()
  }

  const truncateDescription = (description, limit) => {
    if (description.length <= limit) {
      return description
    }

    // Truncate the description to the specified limit and add an ellipsis
    return `${description.substring(0, limit)}...`
  }
  // Pagination Logic
  const indexOfLastItem = currentPage * itemsPerPage
  const indexOfFirstItem = indexOfLastItem - itemsPerPage
  const currentItems = tackleData.slice(indexOfFirstItem, indexOfLastItem)

  const paginate = (pageNumber) => setCurrentPage(pageNumber)

  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="mb-0 text-info">TACKLE MASTITIS</h4>
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <Link to="/dashboard">Home</Link>
                    </li>
                    <li className="breadcrumb-item active text-info">Tackle Mastitis </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          <div className="row m-2">
            <div className="col-xl-12">
              <div className="card custom-shadow rounded-lg border">
                <div className="card-body">
                  <div className="">
                    <div className="dataTables_length">
                      <div></div>
                      <div className="search-box">
                        <Button variant="primary" className="my-2" onClick={handleAddShow}>
                          Add New
                        </Button>
                        <Modal show={showAddModal} onHide={handleAddClose}>
                          <Modal.Header closeButton>
                            <Modal.Title>Add New </Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            <Form>
                              <Form.Group>
                                <Form.Label>Heading</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Enter Heading"
                                  onChange={(e) =>
                                    setNewTackleData({ ...newTackleData, heading: e.target.value })
                                  }
                                />
                              </Form.Group>
                              <Form.Group>
                                <Form.Label>Description</Form.Label>
                                <Form.Control
                                  as="textarea"
                                  rows={7}
                                  placeholder="Enter Description"
                                  onChange={(e) =>
                                    setNewTackleData({
                                      ...newTackleData,
                                      description: e.target.value,
                                    })
                                  }
                                />
                              </Form.Group>
                              <Form.Group>
                                <Form.Label>Upload Image</Form.Label>
                                <Form.Control type="file" onChange={handleFileChange} />
                              </Form.Group>
                            </Form>
                          </Modal.Body>
                          <Modal.Footer>
                            <Button variant="secondary" onClick={handleAddClose}>
                              Close
                            </Button>
                            <Button variant="primary" onClick={handleAddSubmit}>
                              Save
                            </Button>
                          </Modal.Footer>
                        </Modal>
                      </div>
                    </div>

                    <Table responsive bordered hover>
                      <thead>
                        <tr>
                          <th>Heading</th>
                          <th>Description</th>

                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentItems.map((data, index) => (
                          <tr key={data._id}>
                            <td>{data.heading}</td>
                            <td>{truncateDescription(data.description, 100)}</td>

                            <td>
                              <div className="icon-container">
                                <Button
                                  variant="info"
                                  className="btn-sm  text-white"
                                  onClick={() =>
                                    handleEditClick(data._id, data.heading, data.description)
                                  }
                                >
                                  <FaRegEdit title="Edit " />
                                </Button>
                                <Button
                                  variant="danger"
                                  className="btn-sm"
                                  onClick={() => handleDelete(data._id)}
                                >
                                  <FaTrashAlt title="Delete " />
                                </Button>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    {/* Edit Modal */}
                    <Modal show={showEditModal} onHide={handleEditClose}>
                      <Modal.Header closeButton>
                        <Modal.Title>Edit Tackle Mastitis</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Form>
                          <Form.Group>
                            <Form.Label>Heading</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter Heading"
                              value={editTackleData.heading}
                              onChange={(e) =>
                                setEditTackleData({ ...editTackleData, heading: e.target.value })
                              }
                            />
                          </Form.Group>
                          <Form.Group>
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                              as="textarea"
                              rows={3}
                              placeholder="Enter Description"
                              value={editTackleData.description}
                              onChange={(e) =>
                                setEditTackleData({
                                  ...editTackleData,
                                  description: e.target.value,
                                })
                              }
                            />
                          </Form.Group>
                          <Form.Group>
                            <Form.Label>Upload Image</Form.Label>
                            <Form.Control
                              type="file"
                              onChange={(e) =>
                                setEditTackleData({ ...editTackleData, image: e.target.files[0] })
                              }
                            />
                          </Form.Group>
                        </Form>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="secondary" onClick={handleEditClose}>
                          Close
                        </Button>
                        <Button variant="primary" onClick={handleEditSubmit}>
                          Save
                        </Button>
                      </Modal.Footer>
                    </Modal>
                    {/* Pagination */}
                    <div className="pagination">
                      {Array.from(
                        { length: Math.ceil(tackleData.length / itemsPerPage) },
                        (_, index) => (
                          <Button
                            className="mx-1"
                            key={index + 1}
                            onClick={() => paginate(index + 1)}
                          >
                            {index + 1}
                          </Button>
                        ),
                      )}
                    </div>
                    <div className="container"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TackleMastitis
