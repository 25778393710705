import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button, Modal, Form, Table } from 'react-bootstrap';
import { FaRegEdit, FaTrashAlt } from 'react-icons/fa';
import { Constants } from 'src/Constants';
import Swal from 'sweetalert2';

const MastitisNews = () => {
  const [tackleData, setTackleData] = useState([]); // You can keep it for future use

  // Static product items
  const [newsData, setNewsData] = useState({
    heading: '',
    description: '',
    image: null,
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);

  useEffect(() => {
    fetchTackleData();
  }, []);

  const fetchTackleData = async () => {
    try {
      const response = await fetch(Constants.getMastitisNews);
      const data = await response.json();
      setTackleData(data);
    } catch (error) {
      console.error('Error fetching tackle mastitis data:', error);
    }
  };

  const [showAddModal, setShowAddModal] = useState(false); // Add modal state

  const handleAddClose = () => setShowAddModal(false);
  const handleAddShow = () => setShowAddModal(true);

  const handleAddSubmit = async () => {
    try {
      if (!newsData.heading || !newsData.description || !newsData.image) {
        Swal.fire({
          text: 'Please fill in all required fields: Heading, Description, and Image.',
          icon: 'error',
        });
        return;
      }
      const formData = new FormData();
      formData.append('heading', newsData.heading);
      formData.append('description', newsData.description);
      formData.append('image', newsData.image);

      const response = await fetch(Constants.postMastitisNews, {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        Swal.fire({
          title: 'Success!',
          text: 'News added successfully',
          icon: 'success',
        });
        handleAddClose();
        fetchTackleData(); // Refresh the data after adding
      } else {
        Swal.fire({
          title: 'Error!',
          text: 'Failed to add News',
          icon: 'error',
        });
      }
    } catch (error) {
      console.error('Error adding News:', error);
    }
  };

  const handleFileChange = (e) => {
    setNewsData({
      ...newsData,
      image: e.target.files[0],
    });
  };

  const handleDelete = async (id) => {
    try {
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      });

      if (result.isConfirmed) {
        const response = await fetch(Constants.deleteMastitisNews + id, {
          method: 'DELETE',
        });

        if (response.ok) {
          Swal.fire({
            title: 'Success!',
            text: 'News deleted successfully',
            icon: 'success',
          });
          fetchTackleData(); // Refresh the data after deleting
        } else {
          Swal.fire({
            title: 'Error!',
            text: 'Failed to delete News',
            icon: 'error',
          });
        }
      }
    } catch (error) {
      console.error('Error deleting News:', error);
    }
  };

  const [showEditModal, setShowEditModal] = useState(false); // Edit modal state
  const [editData, setEditData] = useState({
    id: null,
    heading: '',
    description: '',
    image: null,
  });

  const handleEditClose = () => setShowEditModal(false);
  const handleEditShow = async (id) => {
    try {
      const response = await fetch(`${Constants.getMastitisNewsbyId}${id}`);
      const data = await response.json();

      setEditData({
        id: data._id,
        heading: data.heading,
        description: data.description,
        image: data.image, // Store the image name
      });

      setShowEditModal(true);
    } catch (error) {
      console.error('Error fetching News by ID:', error);
    }
  };

  const handleEditSubmit = async () => {
    try {
      const formData = new FormData();
      formData.append('heading', editData.heading);
      formData.append('description', editData.description);

      if (editData.image instanceof File) {
        formData.append('image', editData.image);
      } else {
        const existingImageBlob = await fetch(`https://mastitisapi.goigi.me/static/${editData.image}`).then(r => r.blob());
        const existingImageFile = new File([existingImageBlob], editData.image);
        formData.append('image', existingImageFile);
      }

      const response = await fetch(`${Constants.updateMastitisNews}${editData.id}`, {
        method: 'PATCH',
        body: formData,
      });

      if (response.ok) {
        Swal.fire({
          title: 'Success!',
          text: 'News updated successfully',
          icon: 'success',
        });
        handleEditClose();
        fetchTackleData();
      } else {
        Swal.fire({
          title: 'Error!',
          text: 'Failed to update News',
          icon: 'error',
        });
      }
    } catch (error) {
      console.error('Error updating News:', error);
    }
  };

  const handleEditFileChange = (e) => {
    setEditData({
      ...editData,
      image: e.target.files[0],
    });
  };

  const truncateDescription = (description, limit) => {
    if (description.length <= limit) {
      return description;
    }

    return `${description.substring(0, limit)}...`;
  };

  // Pagination Logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = tackleData.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="mb-0 text-info">MASTITIS NEWS</h4>
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <Link to="/dashboard">Home</Link>
                    </li>
                    <li className="breadcrumb-item active text-info">Mastitis News</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          <div className="row m-2">
            <div className="col-xl-12">
              <div className="card custom-shadow rounded-lg border">
                <div className="card-body">
                  <div className="">
                    <div className="dataTables_length">
                      <div></div>
                      <div className="search-box">
                        <Button variant="primary" className="my-2" onClick={handleAddShow}>
                          Add New
                        </Button>
                        <Modal show={showAddModal} onHide={handleAddClose}>
                          <Modal.Header closeButton>
                            <Modal.Title>Add New </Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            <Form>
                              <Form.Group>
                                <Form.Label>News Heading</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Enter Heading"
                                  onChange={(e) =>
                                    setNewsData({ ...newsData, heading: e.target.value })
                                  }
                                />
                              </Form.Group>
                              <Form.Group>
                                <Form.Label>News Description</Form.Label>
                                <Form.Control
                                  as="textarea"
                                  rows={7}
                                  placeholder="Enter Description"
                                  onChange={(e) =>
                                    setNewsData({
                                      ...newsData,
                                      description: e.target.value,
                                    })
                                  }
                                />
                              </Form.Group>
                              <Form.Group>
                                <Form.Label>Upload Image</Form.Label>
                                <Form.Control type="file" onChange={handleFileChange} />
                              </Form.Group>
                            </Form>
                          </Modal.Body>
                          <Modal.Footer>
                            <Button variant="secondary" onClick={handleAddClose}>
                              Close
                            </Button>
                            <Button variant="primary" onClick={handleAddSubmit}>
                              Save
                            </Button>
                          </Modal.Footer>
                        </Modal>
                      </div>
                    </div>

                    <Table responsive bordered hover>
                      <thead>
                        <tr>
                          <th>News Heading</th>
                          <th>News Description</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentItems.map((data, index) => (
                          <tr key={data._id}>
                            <td>{data.heading}</td>
                            <td>{truncateDescription(data.description, 100)}</td>
                            <td>
                              <div className="icon-container">
                                <Button
                                  variant="info"
                                  className="btn-sm  text-white"
                                  onClick={() => handleEditShow(data._id)}
                                >
                                  <FaRegEdit title="Edit " />
                                </Button>
                                <Button
                                  variant="danger"
                                  className="btn-sm"
                                  onClick={() => handleDelete(data._id)}
                                >
                                  <FaTrashAlt title="Delete " />
                                </Button>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    {/* Pagination */}
                    <div className="pagination">
                      {Array.from(
                        { length: Math.ceil(tackleData.length / itemsPerPage) },
                        (_, index) => (
                          <Button
                            className="mx-1"
                            key={index + 1}
                            onClick={() => paginate(index + 1)}
                          >
                            {index + 1}
                          </Button>
                        ),
                      )}
                    </div>
                    <div className="container"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Edit News Modal */}
      <Modal show={showEditModal} onHide={handleEditClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit News</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>News Heading</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Heading"
                value={editData.heading}
                onChange={(e) => setEditData({ ...editData, heading: e.target.value })}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>News Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Enter Description"
                value={editData.description}
                onChange={(e) => setEditData({ ...editData, description: e.target.value })}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Upload Image</Form.Label>
              <Form.Control type="file" onChange={handleEditFileChange} />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleEditClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleEditSubmit}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default MastitisNews;
