import './login.css'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import useAuthStore from 'src/hooks/useAuthStore'
import logo from '../../assets/brand/logoheader.png'
import { Constants } from 'src/Constants'
import { FaEye, FaEyeSlash } from 'react-icons/fa'
import { FaUser } from 'react-icons/fa'
const Login = () => {
  const { setToken } = useAuthStore()
  const router = useNavigate()
  const [formData, setFormData] = useState({
    username: '',
    password: '',
  })
  const [showPassword, setShowPassword] = useState(false)

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData({
      ...formData,
      [name]: value,
    })
  }
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }
  const handleSubmit = async (e) => {
    e.preventDefault()

    try {
      const response = await fetch(Constants.adminLogIn, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      })

      const data = await response.json()

      if (response.ok && data.token) {
        localStorage.setItem('token', data.token)
        setToken(data.token)
        router('/dashboard') // Navigate to the Dashboard component
        console.log('Login successful!')
      } else {
        console.log(`Login failed with status: ${response.status}`)
        if (response.status === 400) {
          Swal.fire({
            icon: 'error',
            title: 'Login Failed',
            text: 'Invalid credentials. Please try again.',
          })
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Login Failed',
            text: 'An error occurred during login. Please try again later.',
          })
        }
      }
    } catch (err) {
      console.log(`Login error: ${err}`)
      Swal.fire({
        icon: 'error',
        title: 'Login Failed',
        text: 'An error occurred during login. Please try again later.',
      })
    }
  }

  return (
    <div className="d-flex align-items-center justify-content-center w-100 vh-100">
      <form className="form_container " onSubmit={handleSubmit}>
        <div className="logo_container" style={{ marginRight: '68px' }}>
          <img src={logo} className="w-40 h-40" alt="Logo" />
        </div>

        <div className="title_container">
          <p className="title">Welcome To MastitisZero Admin</p>
          <span className="subtitle">Login to your Account</span>
        </div>
        <br />
        <div className="input_container">
          <br />
          <label className="input_label" htmlFor="username_field">
            Username
          </label>
          <FaUser className="icon" /> 
          <input
            onChange={handleChange}
            name="username"
            type="text"
            className="input_field"
            id="username_field"
            placeholder="Username"
          />
        </div>
        <div className="input_container">
          <label className="input_label" htmlFor="password_field">
            Password
          </label>
          <div className="password_input">
            <input
              onChange={handleChange}
              name="password"
              type={showPassword ? 'text' : 'password'}
              className="input_field"
              id="password_field"
            />
            <button type="button" onClick={togglePasswordVisibility} className="eye_button">
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </button>
          </div>
        </div>
        <button title="Sign In" type="submit" className="sign-in_btn">
          <span>Log In</span>
        </button>
      </form>
    </div>
  )
}

export default Login
