import React, { useState, useEffect } from 'react'
import { FaEye, FaTrash } from 'react-icons/fa'
import { useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { Container, Row, Col, Table, Button, Pagination } from 'react-bootstrap'
import { Constants } from 'src/Constants'
import { AiOutlineArrowLeft, AiOutlineArrowRight } from 'react-icons/ai'
const MySwal = withReactContent(Swal)

const CustomerList = () => {
  const [customers, setCustomers] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const location = useLocation()
  const [selectedUserType, setSelectedUserType] = useState(
    location.state && location.state.selectedUserType ? location.state.selectedUserType : '',
  )
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [sortColumn, setSortColumn] = useState('')
  const [sortOrder, setSortOrder] = useState('asc')

  useEffect(() => {
    const fetchCustomers = async () => {
      try {
        const response = await fetch(Constants.getAllUsers)
        if (response.ok) {
          const data = await response.json()
          setCustomers(data)
        } else {
          console.error('Failed to fetch customers')
        }
      } catch (error) {
        console.error('Error fetching customers:', error)
      }
    }

    fetchCustomers()
  }, [])

  const filteredCustomers = customers.filter((customer) => {
    const fullName = `${customer.firstName} ${customer.lastName}`
    return (
      (selectedUserType === '' || customer.userType === selectedUserType) &&
      (customer.username.toLowerCase().includes(searchTerm.toLowerCase()) ||
        fullName.toLowerCase().includes(searchTerm.toLowerCase()))
    )
  })
  const handleSort = (column) => {
    if (sortColumn === column) {
      setSortOrder((prevOrder) => (prevOrder === 'asc' ? 'desc' : 'asc'))
    } else {
      setSortColumn(column)
      setSortOrder('asc')
    }
  }

  const sortedCustomers = filteredCustomers.sort((a, b) => {
    const columnA = a[sortColumn]
    const columnB = b[sortColumn]

    if (columnA && columnB) {
      const valueA = typeof columnA === 'string' ? columnA : String(columnA)
      const valueB = typeof columnB === 'string' ? columnB : String(columnB)

      if (sortOrder === 'asc') {
        return valueA.localeCompare(valueB)
      } else {
        return valueB.localeCompare(valueA)
      }
    }

    return 0
  })
  const indexOfLastItem = currentPage * itemsPerPage
  const indexOfFirstItem = indexOfLastItem - itemsPerPage
  const currentItems = filteredCustomers.slice(indexOfFirstItem, indexOfLastItem)

  const paginate = (pageNumber) => setCurrentPage(pageNumber)
  const handleDelete = async (userId) => {
    // Show confirmation dialog before deleting
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this user!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await fetch(`${Constants.deleteUser}${userId}`, {
            method: 'DELETE',
          })
          if (response.ok) {
            const data = await response.json()
            // Update the state to remove the deleted user
            setCustomers(customers.filter((user) => user._id !== userId))
            Swal.fire('Deleted!', data.message, 'success')
          } else {
            Swal.fire('Error', 'Failed to delete user', 'error')
          }
        } catch (error) {
          console.error('Error deleting user:', error)
          Swal.fire('Error', 'Failed to delete user', 'error')
        }
      }
    })
  }
  const totalPages = Math.ceil(filteredCustomers.length / itemsPerPage)

  // Function to handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber)
  }
  return (
    <div className="main-content">
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="mb-0 text-info">USER LIST</h4>
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <Link to="/dashboard">Home</Link>
                    </li>
                    <li className="breadcrumb-item text-info active">User List</li>
                  </ol>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="card custom-shadow rounded-lg border my-3">
                <div className="card-body">
                  <Row>
                    <Col md={4} xs={12} className="mb-3 text-center">
                      <h3 className="fw-bolder text-info mb-0">{customers.length}</h3>
                      <p className="text-secondary">Total Users</p>
                    </Col>
                  </Row>

                  <div className="dataTables_length mb-2">
                    <label>
                      <select
                        name="datatable_length"
                        className="form-control form-select"
                        value={selectedUserType}
                        onChange={(e) => setSelectedUserType(e.target.value)}
                      >
                        <option value="">Select Type</option>
                        <option value="Farmer">Farmer</option>
                        <option value="Veterinarian">Veterinarian</option>
                        <option value="Normal User">Normal User</option>
                        <option value="Licensed User">Licensed User</option>
                        <option value="Consultant">Consultant</option>
                        <option value="Processor">Processor</option>
                      </select>
                    </label>
                    <div className="search-box">
                      <input
                        type="text"
                        id="search"
                        placeholder="Search by Username or Name"
                        name="search"
                        className="form-control "
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="table-responsive">
                    <Table responsive bordered hover>
                      <thead>
                        <tr>
                          <th onClick={() => handleSort('username')}>
                            User Name
                            {sortColumn === 'username' && (
                              <span>{sortOrder === 'asc' ? ' 🔽' : ' 🔼'}</span>
                            )}
                          </th>
                          <th onClick={() => handleSort('firstName')}>
                            First Name
                            {sortColumn === 'firstName' && (
                              <span>{sortOrder === 'asc' ? ' 🔽' : ' 🔼'}</span>
                            )}
                          </th>
                          <th onClick={() => handleSort('lastName')}>
                            Last Name
                            {sortColumn === 'lastName' && (
                              <span>{sortOrder === 'asc' ? ' 🔽' : ' 🔼'}</span>
                            )}
                          </th>
                          <th onClick={() => handleSort('userType')}>
                            User Type
                            {sortColumn === 'userType' && (
                              <span>{sortOrder === 'asc' ? ' 🔽' : ' 🔼'}</span>
                            )}
                          </th>
                          <th onClick={() => handleSort('role')}>
                            Role
                            {sortColumn === 'role' && (
                              <span>{sortOrder === 'asc' ? ' 🔽' : ' 🔼'}</span>
                            )}
                          </th>
                          <th onClick={() => handleSort('country')}>
                            Country
                            {sortColumn === 'country' && (
                              <span>{sortOrder === 'asc' ? ' 🔽' : ' 🔼'}</span>
                            )}
                          </th>
                          <th onClick={() => handleSort('contactNumbers')}>
                            Phone
                            {sortColumn === 'contactNumbers' && (
                              <span>{sortOrder === 'asc' ? ' 🔽' : ' 🔼'}</span>
                            )}
                          </th>
                          <th onClick={() => handleSort('emailAddresses')}>
                            Email
                            {sortColumn === 'emailAddresses' && (
                              <span>{sortOrder === 'asc' ? ' 🔽' : ' 🔼'}</span>
                            )}
                          </th>
                          <th>Actions</th>
                        </tr>
                      </thead>

                      <tbody>
                        {currentItems?.map((customer, index) => (
                          <tr key={customer?._id}>
                            <td>{customer?.username}</td>
                            <td>{customer?.firstName}</td>
                            <td>{customer?.lastName}</td>
                            <td>{customer?.userType}</td>
                            <td>{customer?.role}</td>
                            <td>{customer?.country}</td>
                            <td>{customer?.contactNumbers}</td>
                            <td>{customer?.emailAddresses}</td>
                            <td>
                              <div className="icon-container">
                                <Link
                                  className="btn btn-sm btn-info text-white"
                                  to={`/customerDetails/${customer._id}`}
                                >
                                  <FaEye title="View Customer" />
                                </Link>
                                <Button
                                  variant="danger"
                                  className="btn-sm ml-2"
                                  onClick={() => handleDelete(customer._id)}
                                >
                                  <FaTrash title="Delete Customer" />
                                </Button>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                  <Container>
                    <div className="col-sm-12 col-md-5">
                      <div className="dataTables_length">
                        Showing {indexOfFirstItem + 1} to{' '}
                        {Math.min(indexOfLastItem, filteredCustomers.length)} of{' '}
                        {filteredCustomers.length} entries
                      </div>
                    </div>
                    <div className="d-flex justify-content-center">
                      <Pagination>
                        <Pagination.First
                          onClick={() => handlePageChange(1)}
                          disabled={currentPage === 1}
                        />
                        <Pagination.Prev
                          onClick={() => handlePageChange(currentPage - 1)}
                          disabled={currentPage === 1}
                        />

                        {[...Array(totalPages).keys()].map((page) => (
                          <Pagination.Item
                            key={page + 1}
                            active={page + 1 === currentPage}
                            onClick={() => handlePageChange(page + 1)}
                          >
                            {page + 1}
                          </Pagination.Item>
                        ))}

                        <Pagination.Next
                          onClick={() => handlePageChange(currentPage + 1)}
                          disabled={currentPage === totalPages}
                        />
                        <Pagination.Last
                          onClick={() => handlePageChange(totalPages)}
                          disabled={currentPage === totalPages}
                        />
                      </Pagination>
                    </div>
                  </Container>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}

export default CustomerList
