import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Button, Modal, Form, Table } from 'react-bootstrap'
import { FaRegEdit, FaTrashAlt } from 'react-icons/fa'
import Swal from 'sweetalert2'

const Team = () => {
  const [teamData, setTeamData] = useState([])
  const [showAddModal, setShowAddModal] = useState(false)
  const [showEditModal, setShowEditModal] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage] = useState(10)

  const [newTeamMember, setNewTeamMember] = useState({
    name: '',
    designation: '',
    image: null,
    experience: '',
    qualification: '',
    teamType: '', // Add the teamType field
    phone: '',
    email: '',
  })

  const [editTeamMember, setEditTeamMember] = useState({
    id: null,
    name: '',
    designation: '',
    image: null,
    experience: '',
    qualification: '',
    teamType: '', // Add the teamType field
    phone: '',
    email: '',
  })

  useEffect(() => {
    fetchTeamData()
  }, [])

  const fetchTeamData = async () => {
    try {
      const response = await fetch('https://mastitisapi.goigi.me/api/teams')
      const data = await response.json()
      setTeamData(data)
    } catch (error) {
      console.error('Error fetching team data:', error)
    }
  }

  const handleAddClose = () => setShowAddModal(false)
  const handleAddShow = () => setShowAddModal(true)

  const handleAddSubmit = async () => {
    try {
      // Check if name, designation, and teamType are provided
      if (!newTeamMember.name || !newTeamMember.designation || !newTeamMember.teamType) {
        Swal.fire({
          text: 'Please fill in the required fields: Name, Designation, and Team Type.',
          icon: 'error',
        })
        return
      }

      const formData = new FormData()
      formData.append('name', newTeamMember.name)
      formData.append('designation', newTeamMember.designation)
      formData.append('teamType', newTeamMember.teamType) // Add teamType
      if (newTeamMember.image) formData.append('image', newTeamMember.image)
      if (newTeamMember.experience) formData.append('experience', newTeamMember.experience)
      if (newTeamMember.qualification) formData.append('qualification', newTeamMember.qualification)

      if (newTeamMember.email) formData.append('email', newTeamMember.email)
      if (newTeamMember.phone) formData.append('phone', newTeamMember.phone)

      const response = await fetch('https://mastitisapi.goigi.me/api/teams', {
        method: 'POST',
        body: formData,
      })

      if (response.ok) {
        Swal.fire({
          title: 'Success!',
          text: 'Team member added successfully',
          icon: 'success',
        })
        handleAddClose()
        fetchTeamData() // Refresh the data after adding
      } else {
        Swal.fire({
          title: 'Error!',
          text: 'Failed to add team member',
          icon: 'error',
        })
      }
    } catch (error) {
      console.error('Error adding team member:', error)
    }
  }

  const handleFileChange = (e) => {
    setNewTeamMember({
      ...newTeamMember,
      image: e.target.files[0],
    })
  }

  const handleDelete = async (id) => {
    try {
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      })

      if (result.isConfirmed) {
        const response = await fetch(`https://mastitisapi.goigi.me/api/teams/${id}`, {
          method: 'DELETE',
        })

        if (response.ok) {
          Swal.fire({
            title: 'Success!',
            text: 'Team member deleted successfully',
            icon: 'success',
          })
          fetchTeamData() // Refresh the data after deleting
        } else {
          Swal.fire({
            title: 'Error!',
            text: 'Failed to delete team member',
            icon: 'error',
          })
        }
      }
    } catch (error) {
      console.error('Error deleting team member:', error)
    }
  }

  const handleEditShow = async (id) => {
    try {
      const response = await fetch(`https://mastitisapi.goigi.me/api/teams/${id}`)
      const data = await response.json()

      setEditTeamMember({
        id: data?._id || null,
        name: data?.name || '',
        designation: data?.designation || '',
        image: data?.image || null,
        experience: data?.experience || '',
        qualification: data?.qualification || '',
        teamType: data?.teamType || '', // Add the teamType field
        phone: data?.phone || '',
        email: data?.email || '',
      })

      setShowEditModal(true)
    } catch (error) {
      console.error('Error fetching team member by ID:', error)
    }
  }

  const handleEditClose = () => setShowEditModal(false)

  const handleEditSubmit = async () => {
    try {
      // Check if name, designation, and teamType are provided
      if (!editTeamMember.name || !editTeamMember.designation || !editTeamMember.teamType) {
        Swal.fire({
          text: 'Please fill in the required fields: Name, Designation, and Team Type.',
          icon: 'error',
        })
        return
      }

      const formData = new FormData()
      formData.append('name', editTeamMember.name)
      formData.append('designation', editTeamMember.designation)
      formData.append('teamType', editTeamMember.teamType) // Add teamType
      if (editTeamMember.image instanceof File) {
        formData.append('image', editTeamMember.image)
      } else if (editTeamMember.image) {
        const existingImageBlob = await fetch(
          `https://mastitisapi.goigi.me/static/${editTeamMember.image}`,
        )
          .then((r) => r.blob())
          .catch(() => null)
        if (existingImageBlob) {
          const existingImageFile = new File([existingImageBlob], editTeamMember.image)
          formData.append('image', existingImageFile)
        }
      }
      if (editTeamMember.experience) formData.append('experience', editTeamMember.experience)

      if (editTeamMember.email) formData.append('email', editTeamMember.email)
      if (editTeamMember.phone) formData.append('phone', editTeamMember.phone)
      if (editTeamMember.qualification)
        formData.append('qualification', editTeamMember.qualification)

      const response = await fetch(`https://mastitisapi.goigi.me/api/teams/${editTeamMember.id}`, {
        method: 'PATCH',
        body: formData,
      })

      if (response.ok) {
        Swal.fire({
          title: 'Success!',
          text: 'Team member updated successfully',
          icon: 'success',
        })
        handleEditClose()
        fetchTeamData()
      } else {
        Swal.fire({
          title: 'Error!',
          text: 'Failed to update team member',
          icon: 'error',
        })
      }
    } catch (error) {
      console.error('Error updating team member:', error)
    }
  }

  const handleEditFileChange = (e) => {
    setEditTeamMember({
      ...editTeamMember,
      image: e.target.files[0],
    })
  }

  const truncateText = (text, limit) => {
    return text && text.length > limit ? `${text.substring(0, limit)}...` : text || ''
  }

  const indexOfLastItem = currentPage * itemsPerPage
  const indexOfFirstItem = indexOfLastItem - itemsPerPage
  const currentItems = teamData.slice(indexOfFirstItem, indexOfLastItem)

  const paginate = (pageNumber) => setCurrentPage(pageNumber)

  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="mb-0 text-info">MASTITIS TEAMS</h4>
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <Link to="/dashboard">Home</Link>
                    </li>
                    <li className="breadcrumb-item active text-info">Mastitis Teams</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          <div className="row m-2">
            <div className="col-xl-12">
              <div className="card custom-shadow rounded-lg border">
                <div className="card-body">
                  <div className="dataTables_length">
                    <div className="search-box">
                      <Button variant="primary" className="my-2" onClick={handleAddShow}>
                        Add New Team Member
                      </Button>
                      <Modal show={showAddModal} onHide={handleAddClose}>
                        <Modal.Header closeButton>
                          <Modal.Title>Add New Team Member</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <Form>
                            <Form.Group>
                              <Form.Label>Name</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Enter Name"
                                onChange={(e) =>
                                  setNewTeamMember({ ...newTeamMember, name: e.target.value })
                                }
                              />
                            </Form.Group>
                            <Form.Group>
                              <Form.Label>Designation</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Enter Designation"
                                onChange={(e) =>
                                  setNewTeamMember({
                                    ...newTeamMember,
                                    designation: e.target.value,
                                  })
                                }
                              />
                            </Form.Group>
                            <Form.Group>
                              <Form.Label>Team Type</Form.Label>
                              <Form.Control
                                as="select"
                                onChange={(e) =>
                                  setNewTeamMember({ ...newTeamMember, teamType: e.target.value })
                                }
                              >
                                <option value="">Select Team Type</option>
                                <option value="management">Management</option>
                                <option value="advisory">Advisory</option>
                              </Form.Control>
                            </Form.Group>
                            <Form.Group>
                              <Form.Label>Phone</Form.Label>
                              <Form.Control
                                type="number"
                                placeholder="Enter Phone"
                                onChange={(e) =>
                                  setNewTeamMember({
                                    ...newTeamMember,
                                    phone: e.target.value,
                                  })
                                }
                              />
                            </Form.Group>
                            <Form.Group>
                              <Form.Label>Email</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Enter Email"
                                onChange={(e) =>
                                  setNewTeamMember({
                                    ...newTeamMember,
                                    email: e.target.value,
                                  })
                                }
                              />
                            </Form.Group>
                            <Form.Group>
                              <Form.Label>Experience</Form.Label>
                              <Form.Control
                                as="textarea"
                                rows={3}
                                placeholder="Enter Experience"
                                onChange={(e) =>
                                  setNewTeamMember({ ...newTeamMember, experience: e.target.value })
                                }
                              />
                            </Form.Group>
                            <Form.Group>
                              <Form.Label>Qualification</Form.Label>
                              <Form.Control
                                as="textarea"
                                rows={3}
                                placeholder="Enter Qualification"
                                onChange={(e) =>
                                  setNewTeamMember({
                                    ...newTeamMember,
                                    qualification: e.target.value,
                                  })
                                }
                              />
                            </Form.Group>
                            <Form.Group>
                              <Form.Label>Upload Image</Form.Label>
                              <Form.Control type="file" onChange={handleFileChange} />
                            </Form.Group>
                          </Form>
                        </Modal.Body>
                        <Modal.Footer>
                          <Button variant="secondary" onClick={handleAddClose}>
                            Close
                          </Button>
                          <Button variant="primary" onClick={handleAddSubmit}>
                            Save
                          </Button>
                        </Modal.Footer>
                      </Modal>
                    </div>
                  </div>

                  <Table responsive bordered hover>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Designation</th>
                        <th>Team Type</th> {/* New column */}
                        <th>Experience</th>
                        <th>Qualification</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentItems.map((data) => (
                        <tr key={data?._id}>
                          <td>{data?.name || 'N/A'}</td>
                          <td>{data?.designation || 'N/A'}</td>
                          <td>{data?.teamType ? data.teamType.toUpperCase() : 'N/A'}</td>
                          <td>{truncateText(data?.experience || '', 100)}</td>
                          <td>{truncateText(data?.qualification || '', 100)}</td>
                          <td>
                            <div className="icon-container">
                              <Button
                                variant="info"
                                className="btn-sm text-white"
                                onClick={() => handleEditShow(data?._id)}
                              >
                                <FaRegEdit title="Edit" />
                              </Button>
                              <Button
                                variant="danger"
                                className="btn-sm"
                                onClick={() => handleDelete(data._id)}
                              >
                                <FaTrashAlt title="Delete" />
                              </Button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>

                  <div className="pagination">
                    {Array.from(
                      { length: Math.ceil(teamData.length / itemsPerPage) },
                      (_, index) => (
                        <Button
                          className="mx-1"
                          key={index + 1}
                          onClick={() => paginate(index + 1)}
                        >
                          {index + 1}
                        </Button>
                      ),
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Edit Team Member Modal */}
      <Modal show={showEditModal} onHide={handleEditClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Team Member</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Name"
                value={editTeamMember.name}
                onChange={(e) => setEditTeamMember({ ...editTeamMember, name: e.target.value })}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Designation</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Designation"
                value={editTeamMember.designation}
                onChange={(e) =>
                  setEditTeamMember({ ...editTeamMember, designation: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Team Type</Form.Label>
              <Form.Control
                as="select"
                value={editTeamMember.teamType}
                onChange={(e) => setEditTeamMember({ ...editTeamMember, teamType: e.target.value })}
              >
                <option value="">Select Team Type</option>
                <option value="management">Management</option>
                <option value="advisory">Advisory</option>
              </Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>Phone</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter Phone"
                value={editTeamMember.email}
                onChange={(e) =>
                  setEditTeamMember({
                    ...editTeamMember,
                    phone: e.target.value,
                  })
                }
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Email"
                value={editTeamMember.email}
                onChange={(e) =>
                  setEditTeamMember({
                    ...editTeamMember,
                    email: e.target.value,
                  })
                }
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Experience</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Enter Experience"
                value={editTeamMember.experience}
                onChange={(e) =>
                  setEditTeamMember({ ...editTeamMember, experience: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Qualification</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Enter Qualification"
                value={editTeamMember.qualification}
                onChange={(e) =>
                  setEditTeamMember({ ...editTeamMember, qualification: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Upload Image</Form.Label>
              <Form.Control type="file" onChange={handleEditFileChange} />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleEditClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleEditSubmit}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default Team
