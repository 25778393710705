import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import { ImCross } from 'react-icons/im'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import withReactContent from 'sweetalert2-react-content'
import { Constants } from 'src/Constants'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

const MySwal = withReactContent(Swal)

const EditProduct = () => {
  const { id } = useParams()
  const [loading, setLoading] = useState(true)
  const [formData, setFormData] = useState({
    itemName: '',
    price: '',
    quantityForPackages: '',
    quantityForTwentyLbarrel: '',

    shortDescription: '',
    licencedUserPrice: '',
    farmerPrice: '',
    veterinarianPrice: '',
    productImages: [],
    pdfFile: null,
    videoFile: null,
    attachmentFile: null,
    originalImage: null,
    additionalNotes: '',
    tag:'',
  })

  useEffect(() => {
    const fetchProductDetails = async () => {
      try {
        const response = await fetch(`${Constants.getProductById}${id}`)
        const productData = await response.json()
        const fetchImage = async (imageUrl) => {
        
          const blob = await fetch(`https://mastitisapi.goigi.me/static/${imageUrl}`).then((r) => r.blob());
        
       
          const imageFile = new File([blob], imageUrl);
        
          return Object.assign(imageFile, {
            preview: URL.createObjectURL(blob),
          });
        };
        
        const productImages = productData?.imageUrls.map(async (imageUrl) => {
          return await fetchImage(imageUrl);
        });
        
        const originalImageFile = productData?.originalImage
          ? await (async () => {
             
              const blob = await fetch(`https://mastitisapi.goigi.me/static/${productData.originalImage}`).then(
                (r) => r.blob()
              );
        
             
              return new File([blob], productData.originalImage);
            })()
          : null;
        
       
        const resolvedProductImages = await Promise.all(productImages);
        
        setFormData((prevData) => ({
          ...prevData,
          itemName: productData?.name || '',
          price: productData?.price?.toString() || '',
          quantityForPackages: productData?.quantityForPackages?.toString() || '',
          shortDescription: productData?.shortDescription || '',
          tag : productData?.tag || "",
          licencedUserPrice: productData?.licencedUserPrice?.toString() || '',
          farmerPrice: productData?.farmerPrice?.toString() || '',
          veterinarianPrice: productData?.veterinarianPrice?.toString() || '',
          quantityForTwentyLbarrel: productData?.quantityForTwentyLbarrel?.toString() || '',
          quantityForTwoHundredLbarrel: productData?.quantityForTwoHundredLbarrel?.toString() || '',
          quantityForThousandLbarrel: productData?.quantityForThousandLbarrel?.toString() || '',
          pdfFile: productData?.pdfFile || '',
          videoFile: productData?.videoFile || '',
          attachmentFile: productData?.attachmentFile || '',
          originalImage: originalImageFile,

          productImages: resolvedProductImages,
          additionalNotes: productData?.description,
        }))

        // console.log('Form Data (After Render):', formData)
      } catch (error) {
        console.error('Error fetching product details:', error)
      } finally {
        setLoading(false)
      }
    }

    if (id) {
      fetchProductDetails()
    }
  }, [id])

  const handleChange = (event) => {
    const { name, value, type, checked, files } = event.target
    const newValue = type === 'checkbox' ? checked : type === 'file' ? files[0] : value
    setFormData((prevData) => ({ ...prevData, [name]: newValue }))
  }

  const handleFileUpload = (fieldName) => (e) => {
    const { files } = e.target
    if (files && files.length > 0) {
      setFormData((prevData) => ({ ...prevData, [fieldName]: files[0] }))
    }
  }

  const handleImageUpload = (e) => {
    const { files } = e.target
    setFormData((prevData) => ({
      ...prevData,
      productImages: [...prevData.productImages, ...files],
    }))
  }

  const handleRemoveImage = (index) => {
    setFormData((prevData) => {
      const updatedImages = [...prevData.productImages]
      updatedImages.splice(index, 1)
      return { ...prevData, productImages: updatedImages }
    })
  }

  const handleImageReorder = (result) => {
    if (!result.destination) return
    const reorderedImages = Array.from(formData.productImages)
    const [reorderedImage] = reorderedImages.splice(result.source.index, 1)
    reorderedImages.splice(result.destination.index, 0, reorderedImage)
    setFormData((prevData) => ({ ...prevData, productImages: reorderedImages }))
  }

  const validateForm = () => {
    const { itemName, price, productImages, additionalNotes } = formData

    if (!itemName) {
      MySwal.fire({
        icon: 'error',

        text: 'Please fill in the item name',
      })
      return false // Form is not valid
    }

    if (!price) {
      MySwal.fire({
        icon: 'error',

        text: 'Please fill in both price ',
      })
      return false // Form is not valid
    }

    if (productImages.length === 0) {
      MySwal.fire({
        icon: 'error',

        text: 'Please upload at least one product image',
      })
      return false // Form is not valid
    }

    if (!additionalNotes) {
      MySwal.fire({
        icon: 'error',

        text: 'Please provide additional notes',
      })
      return false
    }

    return true
  }
  const handleSubmit = async () => {
    if (!validateForm()) {
      return
    }

    const Url = `${Constants.updateProduct}${id}`

    try {
      const formDataToSend = new FormData()
      formDataToSend.append('name', formData.itemName)
      formDataToSend.append('description', formData.additionalNotes)
      formDataToSend.append('price', formData.price)
      formDataToSend.append('quantityForPackages', formData.quantityForPackages)

      formDataToSend.append(
        'quantityForTwentyLbarrel',
        formData.quantityForTwentyLbarrel === null ? '' : formData.quantityForTwentyLbarrel,
      )

      formDataToSend.append('shortDescription', formData.shortDescription)
      formDataToSend.append('tag', formData.tag)
      formDataToSend.append('licencedUserPrice', formData.licencedUserPrice)
      formDataToSend.append('farmerPrice', formData.farmerPrice)
      formDataToSend.append('veterinarianPrice', formData.veterinarianPrice)
      formDataToSend.append('pdfFile', formData.pdfFile)
      formDataToSend.append('videoFile', formData.videoFile)
      formDataToSend.append('attachmentFile', formData.attachmentFile)
      if (formData.originalImage instanceof File) {
       
        formDataToSend.append('originalImage', formData.originalImage);
      } else {
       
        formDataToSend.append('originalImage', formData.originalImage || '');
      }

      formData.productImages.forEach((image, index) => {
        formDataToSend.append('productImages', image)
      })

      const response = await fetch(Url, {
        method: 'PUT',
        body: formDataToSend,
      })

      if (response.ok) {
        MySwal.fire({
          icon: 'success',
          title: 'Product updated successfully',
        })
      } else {
        console.error('Error updating product. Status:', response.status)
     
        const errorData = await response.json()
        MySwal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Failed to update product',
        })
      }
    } catch (error) {
      console.error('Error updating product:', error)
      MySwal.fire({
        icon: 'error',
        title: 'Error',
        text: 'An error occurred while updating the product',
      })
    }
  }

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }], // Added more header sizes
      [{ font: [] }], // Allows selecting different font types
      [{ size: ['small', false, 'large', 'huge'] }], // Added size options
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link', 'image'],
      ['clean'],
      [{ color: [] }, { background: [] }], // Allows selecting text and background color
    ],
  }
  
  const formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'link',
    'image',
    'color',
    'background',
  ]
  

  const renderImages = () => (
    <DragDropContext onDragEnd={handleImageReorder}>
      <Droppable droppableId="images">
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef} className="selected-images">
            {formData.productImages.map((image, index) => (
              <Draggable key={index} draggableId={index.toString()} index={index}>
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className="selected-image my-2"
                  >
                    <img src={image.preview} alt={`Image ${index}`} width="100%" height="100%" />
                    <span className="delete-icon" onClick={() => handleRemoveImage(index)}>
                      <ImCross />
                    </span>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <div className="spinner-border" role="status">
          <span className="sr-only"></span>
        </div>
      </div>
    )
  }

  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="mb-0 text-info">EDIT PRODUCT</h4>
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item active text-info">Edit Product</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xl-12">
              <div className="card custom-shadow rounded-lg border my-3">
                <div className="card-body">
                  <h5 className="h6 text-uppercase mb-3">Product Information</h5>
                  <div className="row">
                    <div className="col-lg-12 mb-3">
                      <div className="form-group">
                        <label>Item Name *</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Product Name"
                          name="itemName"
                          value={formData?.itemName}
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div className="col-lg-6 mb-3">
                      <div className="form-group">
                        <label>Price for Normal User *</label>
                        <div className="input-group">
                          <input
                            type="text"
                            name="price"
                            className="form-control"
                            placeholder="Price"
                            aria-label="Text input with radio button"
                            value={formData?.price}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 mb-3">
                      <div className="form-group">
                        <label>Price for Licensed User</label>
                        <div className="input-group">
                          <input
                            type="text"
                            name="licencedUserPrice"
                            className="form-control"
                            placeholder="Price for Licensed User"
                            aria-label="Text input with radio button"
                            value={formData?.licencedUserPrice}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-6 mb-3">
                      <div className="form-group">
                        <label>Price for Veterinarian</label>
                        <div className="input-group">
                          <input
                            type="text"
                            name="veterinarianPrice"
                            className="form-control"
                            placeholder="Price for Veterinarian"
                            aria-label="Text input with radio button"
                            onChange={handleChange}
                            value={formData?.veterinarianPrice}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 mb-3">
                      <div className="form-group">
                        <label>Price for Farmer</label>
                        <div className="input-group">
                          <input
                            type="text"
                            name="farmerPrice"
                            className="form-control"
                            placeholder="Price for Farmer"
                            aria-label="Text input with radio button"
                            onChange={handleChange}
                            value={formData?.farmerPrice}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12 mb-3">
                      <div className="form-group">
                        <label>
                          Package Quantity for Intra Teat & dPlug (please ignore for dCare)
                        </label>
                        <div className="input-group">
                          <input
                            type="number"
                            name="quantityForPackages"
                            className="form-control"
                            placeholder="  Package Quantity for Intra Teat & dPlug (please ignore for dCare)"
                            aria-label="Text input with radio button"
                            value={formData?.quantityForPackages}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                    <p>Quantity input for dCare (please ignore for dPlug & Intra Teat) </p>

                    <div className="col-lg-3 mb-3">
                      <div className="form-group">
                        <label>Quantity in Litre (for dCare)</label>
                        <div className="input-group">
                          <input
                            type="text"
                            name="quantityForTwentyLbarrel"
                            className="form-control"
                            placeholder="Input quantity for dCare"
                            aria-label="Text input with radio button"
                            value={formData?.quantityForTwentyLbarrel}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-lg-3 mb-3">
                      <div className="form-group">
                        <label>Quantity for 200 L Barrel</label>
                        <div className="input-group">
                          <input
                            type="text"
                            name="quantityForTwoHundredLbarrel"
                            className="form-control"
                            placeholder="ltr"
                            aria-label="Text input with radio button"
                            value={formData?.quantityForTwoHundredLbarrel}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 mb-3">
                      <div className="form-group">
                        <label>Quantity for 1000 L Barrel</label>
                        <div className="input-group">
                          <input
                            type="text"
                            name="quantityForThousandLbarrel"
                            className="form-control"
                            placeholder="ltr"
                            aria-label="Text input with radio button"
                            value={formData?.quantityForThousandLbarrel}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>

              <div className="card custom-shadow rounded-lg border my-3">
                <div className="card-body">
                  <h5 className="h6 text- mb-3">
                    Upload Product Images, Original Images, Manual , Video & Description :
                  </h5>
                  <div className="form-group mb-3">
                    <label>Upload Product Images *</label>
                    <div className="input-group">
                      <input
                        type="file"
                        className="form-control"
                        name="attachment"
                        multiple
                        onChange={handleImageUpload}
                      />
                    </div>
                  </div>
                  {renderImages()}
                  <div className="form-group mb-3">
                    <label>Upload Manual (PDF format) *</label>
                    <input
                      type="file"
                      className="form-control"
                      name="pdfFile"
                      onChange={handleFileUpload('pdfFile')}
                    />
                  </div>
                  <div className="form-group mb-3">
                    <label>Upload Video *</label>
                    <input
                      type="file"
                      className="form-control"
                      name="videoFile"
                      onChange={handleFileUpload('videoFile')}
                    />
                  </div>
                  <div className="form-group mb-3">
                    <label>Upload Attachment File *</label>
                    <input
                      type="file"
                      className="form-control"
                      name="attachmentFile"
                      onChange={handleFileUpload('attachmentFile')}
                    />
                  </div>

                  <div className="form-group mb-3">
                    <label>Upload Original Image (Datasheet) *</label>
                    <input
                      type="file"
                      className="form-control"
                      name="originalImage"
                      onChange={handleFileUpload('originalImage')}
                    />
                  </div>

                  <div className="form-group mb-3">
                    <label>Specifications *</label>
                    <textarea
                      className="form-control"
                      name="additionalNotes"
                      value={formData?.shortDescription}
                      onChange={(e) =>
                        setFormData({ ...formData, shortDescription: e.target.value })
                      }
                      placeholder="Add detail description of the product..."
                      style={{ minHeight: '100px' }}
                    ></textarea>
                  </div>
                  <div className="form-group mb-3">
                    <label>Product Details *</label>
                    <div style={{ minHeight: '160px' }}>
                      <ReactQuill
                        theme="snow"
                        modules={modules}
                        formats={formats}
                        value={formData?.additionalNotes}
                        onChange={(value) => setFormData({ ...formData, additionalNotes: value })}
                        placeholder="Add detailed description of the product..."
                      />
                    </div>
                  </div>
                  <div className="form-group mb-3">
                    <label>Tag </label>
                    <textarea
                      className="form-control"
                      name="tag"
                      value={formData?.tag}
                      onChange={(e) =>
                        setFormData({ ...formData, tag: e.target.value })
                      }
                      placeholder="Add Tag "
                      style={{ minHeight: '50px' }}
                    ></textarea>
                  </div>
                  <div className="text-end">
                    {/* <button
                      className="btn btn-outline-info min-btn m-2"
                      onClick={() => resetForm()}
                    >
                      Cancel
                    </button> */}
                    <button className="btn btn-info min-btn m-2" onClick={handleSubmit}>
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EditProduct
