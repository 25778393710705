import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2'
import { ImCross } from 'react-icons/im'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import withReactContent from 'sweetalert2-react-content'
import { Constants } from 'src/Constants'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
const MySwal = withReactContent(Swal)

const AddNewProduct = () => {
  const [formData, setFormData] = useState({
    itemName: '',
    price: '',
    quantityForPackages: '',
    quantityForTwentyLbarrel: '',
    quantityForTwoHundredLbarrel: '',
    quantityForThousandLbarrel: '',
    shortDescription: '',
    tag:'',
    licencedUserPrice: '',
    farmerPrice: '',
    veterinarianPrice: '',
    productImages: [],
    pdfFile: null,
    videoFile: null,
    attachmentFile: null,
    originalImage: null,
    additionalNotes: '',
  })

  const resetForm = () => {
    setFormData((prevData) => ({
      ...prevData,
      itemName: '',
      price: '',
      quantityForPackages: '',
      quantityForTwentyLbarrel: '',
      quantityForTwoHundredLbarrel: '',
      quantityForThousandLbarrel: '',
      shortDescription: '',
      tag:'',
      licencedUserPrice: '',
      farmerPrice: '',
      veterinarianPrice: '',
      productImages: [],
      pdfFile: null,
      videoFile: null,
      attachmentFile: null,
      originalImage: null,
      additionalNotes: '',
    }))
  }

  const handleChange = (event) => {
    const { name, value, type, checked, files } = event.target
    const newValue = type === 'checkbox' ? checked : type === 'file' ? files[0] : value
    setFormData((prevData) => ({ ...prevData, [name]: newValue }))
  }

  const handleFileUpload = (fieldName) => (e) => {
    const { files } = e.target
    setFormData((prevData) => ({ ...prevData, [fieldName]: files[0] }))
  }

  const handleImageUpload = (e) => {
    const { files } = e.target
    setFormData((prevData) => ({
      ...prevData,
      productImages: [...prevData.productImages, ...files],
    }))
  }

  const handleRemoveImage = (index) => {
    setFormData((prevData) => {
      const updatedImages = [...prevData.productImages]
      updatedImages.splice(index, 1)
      return { ...prevData, productImages: updatedImages }
    })
  }
  const handleImageReorder = (result) => {
    if (!result.destination) return
    const reorderedImages = Array.from(formData.productImages)
    const [reorderedImage] = reorderedImages.splice(result.source.index, 1)
    reorderedImages.splice(result.destination.index, 0, reorderedImage)
    setFormData((prevData) => ({ ...prevData, productImages: reorderedImages }))
  }
  const validateForm = () => {
    const {
      itemName,
      price,
    
      productImages,
      shortDescription,
      tag,
      licencedUserPrice,
      farmerPrice,
      veterinarianPrice,
      additionalNotes,
    } = formData

    if (!itemName) {
      MySwal.fire({
        icon: 'error',

        text: 'Please fill in the item name',
      })
      return false // Form is not valid
    }

   

    if (!price || !licencedUserPrice || !farmerPrice || !veterinarianPrice) {
      MySwal.fire({
        icon: 'error',

        text: 'Please fill in all the price',
      })
      return false // Form is not valid
    }

    if (productImages.length === 0) {
      MySwal.fire({
        icon: 'error',

        text: 'Please upload at least one product image',
      })
      return false // Form is not valid
    }

    if (!additionalNotes) {
      MySwal.fire({
        icon: 'error',

        text: 'Please provide descriptions',
      })
      return false // Form is not valid
    }

    return true // Form is valid
  }
  const handleSubmit = async () => {
    if (!validateForm()) {
      // Show an error message or handle invalid form
      // MySwal.fire({
      //   icon: 'error',

      //   text: 'Please fill in all the required fields',
      // })
      return
    }
    const Url = Constants.addProduct

    try {
      const formDataToSend = new FormData()
      formDataToSend.append('name', formData.itemName)
      formDataToSend.append('description', formData.additionalNotes)
      formDataToSend.append('price', formData.price)
      formDataToSend.append('quantityForPackages', formData.quantityForPackages)
      formDataToSend.append('quantityForTwentyLbarrel', formData.quantityForTwentyLbarrel)
      formDataToSend.append('quantityForTwoHundredLbarrel', formData.quantityForTwoHundredLbarrel)
      formDataToSend.append('quantityForThousandLbarrel', formData.quantityForThousandLbarrel)
      formDataToSend.append('shortDescription', formData.shortDescription)
      formDataToSend.append('tag',formData.tag)
      formDataToSend.append('licencedUserPrice', formData.licencedUserPrice)
      formDataToSend.append('farmerPrice', formData.farmerPrice)
      formDataToSend.append('veterinarianPrice', formData.veterinarianPrice)
      formDataToSend.append('pdfFile', formData.pdfFile)
      formDataToSend.append('videoFile', formData.videoFile)
      formDataToSend.append('attachmentFile', formData.attachmentFile)
      formDataToSend.append('originalImage', formData.originalImage)
      formData.productImages.forEach((image, index) => {
        formDataToSend.append('productImages', image)
      })

      const response = await fetch(Url, {
        method: 'POST',
        body: formDataToSend,
      })

      if (response.ok) {
        const result = await response.json()
        MySwal.fire({
          icon: 'success',
          title: 'Product added successfully',
          text: `Product ID: ${result?._id.slice(-2)}`,
        })
        resetForm()
      } else {
        MySwal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Failed to add product',
        })
      }
    } catch (error) {
      console.error('Error adding product:', error)
      MySwal.fire({
        icon: 'error',
        title: 'Error',
        text: 'An error occurred while adding the product',
      })
    }
  }

  const renderImages = () => {
    return (
      <DragDropContext onDragEnd={handleImageReorder}>
        <Droppable droppableId="images">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef} className="selected-images">
              {formData.productImages.map((image, index) => (
                <Draggable key={index} draggableId={index.toString()} index={index}>
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className="selected-image my-2"
                    >
                      <img
                        src={URL.createObjectURL(image)}
                        alt={`Image ${index}`}
                        width="100%"
                        height="100%"
                      />
                      <span className="delete-icon" onClick={() => handleRemoveImage(index)}>
                        <ImCross />
                      </span>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    )
  }

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }], // Added more header sizes
      [{ font: [] }], // Allows selecting different font types
      [{ size: ['small', false, 'large', 'huge'] }], // Added size options
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link', 'image'],
      [{ color: [] }, { background: [] }], // Allows selecting text and background color
      ['clean'],
    ],
  }
  
  const formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'link',
    'image',
    'color',
    'background',
  ]
  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="mb-0 text-info">ADD NEW PRODUCT</h4>
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item active text-info">Add New Product</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xl-12">
              <div className="card custom-shadow rounded-lg border my-3">
                <div className="card-body">
                  <h5 className="h6 text-uppercase mb-3">Product Information</h5>
                  <div className="row">
                    <div className="col-lg-12 mb-3">
                      <div className="form-group">
                        <label>Item Name *</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Product Name"
                          name="itemName"
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div className="col-lg-6 mb-3">
                      <div className="form-group">
                        <label>Price for Normal User *</label>
                        <div className="input-group">
                          <input
                            type="text"
                            name="price"
                            className="form-control"
                            placeholder="Price for Normal User"
                            aria-label="Text input with radio button"
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 mb-3">
                      <div className="form-group">
                        <label>Price for Licensed User</label>
                        <div className="input-group">
                          <input
                            type="text"
                            name="licencedUserPrice"
                            className="form-control"
                            placeholder="Price for Licensed User"
                            aria-label="Text input with radio button"
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-6 mb-3">
                      <div className="form-group">
                        <label>Price for Veterinarian</label>
                        <div className="input-group">
                          <input
                            type="text"
                            name="veterinarianPrice"
                            className="form-control"
                            placeholder="Price for Veterinarian"
                            aria-label="Text input with radio button"
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 mb-3">
                      <div className="form-group">
                        <label>Price for Farmer</label>
                        <div className="input-group">
                          <input
                            type="text"
                            name="farmerPrice"
                            className="form-control"
                            placeholder="Price for Farmer"
                            aria-label="Text input with radio button"
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 mb-3">
                      <div className="form-group">
                        <label>
                          Package Quantity for Intra Teat & dPlug (please ignore for dCare){' '}
                        </label>
                        <div className="input-group">
                          <input
                            type="number"
                            name="quantityForPackages"
                            className="form-control"
                            placeholder="Package Quantity for Intra Teat & dPlug"
                            aria-label="Text input with radio button"
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>

                    <p>Quantity input for dCare (please ignore for dPlug & Intra Teat) </p>

                    <div className="col-lg-6 mb-3">
                      <div className="form-group">
                        <label>Quantity in Litre</label>
                        <div className="input-group">
                          <input
                            type="text"
                            name="quantityForTwentyLbarrel"
                            className="form-control"
                            placeholder="Input quantity for dCare"
                            aria-label="Text input with radio button"
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-lg-3 mb-3">
                      <div className="form-group">
                        <label>Quantity for 200 L Barrel</label>
                        <div className="input-group">
                          <input
                            type="text"
                            name="quantityForTwoHundredLbarrel"
                            className="form-control"
                            placeholder="ltr"
                            aria-label="Text input with radio button"
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 mb-3">
                      <div className="form-group">
                        <label>Quantity for 1000 L Barrel</label>
                        <div className="input-group">
                          <input
                            type="text"
                            name="quantityForThousandLbarrel"
                            className="form-control"
                            placeholder="ltr"
                            aria-label="Text input with radio button"
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>

              <div className="card custom-shadow rounded-lg border my-3">
                <div className="card-body">
                  <h5 className="h6 text- mb-3">
                    Upload Product  Images, Original Images, Manual , Video & Description :
                  </h5>
                  <div className="form-group mb-3">
                    <label>Upload Product Images *</label>
                    <div className="input-group">
                      <input
                        type="file"
                        className="form-control"
                        name="attachment"
                        multiple
                        onChange={handleImageUpload}
                      />
                    </div>
                  </div>
                  {renderImages()}

                  <div className="form-group mb-3">
                    <label>Upload Manual (PDF format) *</label>
                    <div className="input-group">
                      <input
                        type="file"
                        className="form-control"
                        name="pdfFile"
                        onChange={handleFileUpload('pdfFile')}
                      />
                    </div>
                  </div>
                  <div className="form-group mb-3">
                    <label>Upload Video *</label>
                    <div className="input-group">
                      <input
                        type="file"
                        className="form-control"
                        name="videoFile"
                        onChange={handleFileUpload('videoFile')}
                      />
                    </div>
                  </div>
                 
                  <div className="form-group mb-3">
                    <label>Upload Attachment *</label>
                    <div className="input-group">
                      <input
                        type="file"
                        className="form-control"
                        name="attachmentFile"
                        onChange={handleFileUpload('attachmentFile')}
                      />
                    </div>
                  </div>
                  <div className="form-group mb-3">
                    <label>Upload Original Image (Datasheet) *</label>
                    <div className="input-group">
                      <input
                        type="file"
                        className="form-control"
                        name="originalImage"
                        onChange={handleFileUpload('originalImage')}
                      />
                    </div>
                  </div>
                  <div className="form-group mb-3">
                    <label>Specifications</label>
                    <textarea
                      className="form-control"
                      name="additionalNotes"
                      onChange={(e) =>
                        setFormData({ ...formData, shortDescription: e.target.value })
                      }
                      placeholder="Add specifications of the product..."
                      style={{ minHeight: '100px' }}
                    ></textarea>
                  </div>
                  <div className="form-group mb-3">
                    <label>Product Details *</label>
                    <div style={{ minHeight: '160px' }}>
                      <ReactQuill
                        theme="snow"
                        modules={modules}
                        formats={formats}
                        value={formData.additionalNotes}
                        onChange={(value) => setFormData({ ...formData, additionalNotes: value })}
                        placeholder="Add detailed description of the product..."
                      />
                    </div>
                  </div>
                  <div className="form-group mb-3">
                    <label>Tag</label>
                    <textarea
                      className="form-control"
                      name="tag"
                      onChange={(e) =>
                        setFormData({ ...formData, tag: e.target.value })
                      }
                      placeholder="Add Tag"
                      style={{ minHeight: '50px' }}
                    ></textarea>
                  </div>
                  <div className="text-end">
                    <button
                      className="btn btn-outline-info min-btn m-2"
                      onClick={() => resetForm()}
                    >
                      Cancel
                    </button>
                    <button className="btn btn-info min-btn m-2" onClick={handleSubmit}>
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddNewProduct
