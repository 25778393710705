import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Button, Modal, Form, Table } from 'react-bootstrap'
import { FaRegEdit, FaTrashAlt } from 'react-icons/fa'
import { Constants } from 'src/Constants'
import Swal from 'sweetalert2'

const SubscriptionPackages = () => {
  const [projectData, setProjectData] = useState([]) // You can keep it for future use
  const [selectedSubscription, setselectedSubscription] = useState([])
  const [newSubscriptionData, setNewSubscriptionData] = useState({
    name: '',
    features: '',
    price: 0,
  })

  // Static product items

  const [showAddModal, setShowAddModal] = useState(false) // Add modal state
  const [showEditModal, setShowEditModal] = useState(false) // Edit modal state
  const [selectedProduct, setSelectedSubscription] = useState(null)

  const handleAddClose = () => setShowAddModal(false)
  const handleAddShow = () => setShowAddModal(true)

  const handleEditClose = () => {
    setShowEditModal(false)
    setSelectedSubscription(null)
  }
  const handleEditShow = (product) => {
    setSelectedSubscription(product)
    setShowEditModal(true)
  }
  const fetchSubscriptions = async () => {
    try {
      const response = await fetch(Constants.getAllSubscriptions)
      const subscriptions = await response.json()
      setselectedSubscription(subscriptions)
    } catch (error) {
      console.error('Error fetching subscriptions:', error.message)
    }
  }

  useEffect(() => {
    fetchSubscriptions()
  }, [])

  const handleAddSubscription = async () => {
    try {
      if (
        !newSubscriptionData.name ||
        !newSubscriptionData.features ||
        !newSubscriptionData.price
      ) {
        Swal.fire({
          
          text: 'Please fill in all required fields: Package Name, Package Features, and Total Cost.',
          icon: 'error',
        })
        return
      }
      await fetch(Constants.createNewSubscriptions, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newSubscriptionData),
      })
      Swal.fire({
        title: 'Success!',
        text: 'Subscription package added successfully',
        icon: 'success',
      })

      handleAddClose()
      fetchSubscriptions() // Refresh the subscription list
    } catch (error) {
      console.error('Error adding subscription:', error.message)
    }
  }

  const handleUpdateSubscription = async (updatedSubscription) => {
    try {
      if (
        !updatedSubscription.name ||
        !updatedSubscription.features ||
        !updatedSubscription.price
      ) {
        Swal.fire({
        
          text: 'Please fill in all required fields: Subscription Name, Subscription Description, and Total Cost.',
          icon: 'error',
        })
        return
      }
      const url = `${Constants.updateSubscription}${updatedSubscription._id}`
      await fetch(url, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedSubscription),
      })
      Swal.fire({
        title: 'Success!',
        text: 'Subscription package updated successfully',
        icon: 'success',
      })
      handleEditClose()
      fetchSubscriptions() // Refresh the subscription list
    } catch (error) {
      console.error('Error updating subscription:', error.message)
    }
  }

  const handleDeleteSubscription = async (subscriptionId) => {
    try {
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      })

      if (result.isConfirmed) {
        const url = `${Constants.deleteSubscription}${subscriptionId}`
        await fetch(url, {
          method: 'DELETE',
        })

        Swal.fire({
          title: 'Success!',
          text: 'Subscription package deleted successfully',
          icon: 'success',
        })

        fetchSubscriptions() // Refresh the subscription list
      }
    } catch (error) {
      console.error('Error deleting subscription:', error.message)
    }
  }
  // This part of the code is mostly the same as your original code
  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="mb-0 text-info">SUBSCRIPTION PACKAGES</h4>
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <Link to="/dashboard">Home</Link>
                    </li>
                    <li className="breadcrumb-item active text-info">Subscription </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          <div className="row m-2">
            <div className="col-xl-12">
              <div className="card custom-shadow rounded-lg border">
                <div className="card-body">
                  <div className="">
                    <div className="dataTables_length">
                      <div></div>
                      <div className="search-box">
                        <Button variant="primary" className="my-2" onClick={handleAddShow}>
                          Add New Package
                        </Button>
                        <Modal show={showAddModal} onHide={handleAddClose}>
                          <Modal.Header closeButton>
                            <Modal.Title>Add New Package</Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            <Form>
                              <Form.Group>
                                <Form.Label>Package Name</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Enter subscription name"
                                  onChange={(e) =>
                                    setNewSubscriptionData({
                                      ...newSubscriptionData,
                                      name: e.target.value,
                                    })
                                  }
                                />
                              </Form.Group>
                              <Form.Group>
                                <Form.Label>Package Features</Form.Label>
                                <Form.Control
                                  as="textarea"
                                  rows={4}
                                  placeholder="Enter subscription description"
                                  onChange={(e) =>
                                    setNewSubscriptionData({
                                      ...newSubscriptionData,
                                      features: e.target.value,
                                    })
                                  }
                                />
                              </Form.Group>
                              <Form.Group>
                                <Form.Label>Total Cost</Form.Label>
                                <Form.Control
                                  type="number"
                                  placeholder="Enter total cost"
                                  onChange={(e) =>
                                    setNewSubscriptionData({
                                      ...newSubscriptionData,
                                      price: e.target.value,
                                    })
                                  }
                                />
                              </Form.Group>
                            </Form>
                          </Modal.Body>
                          <Modal.Footer>
                            <Button variant="secondary" onClick={handleAddClose}>
                              Close
                            </Button>
                            <Button variant="primary" onClick={handleAddSubscription}>
                              Save Subscription
                            </Button>
                          </Modal.Footer>
                        </Modal>
                      </div>
                    </div>

                    <Table responsive bordered hover>
                      <thead>
                        <tr>
                          <th>Package Name</th>
                          <th>Package Features</th>
                          <th>Cost</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {selectedSubscription.map((product, index) => (
                          <tr key={product._id}>
                            <td>{product.name}</td>
                            <td>{product.features}</td>
                            <td>$ {product.price} /month </td>
                            <td>
                              <div className="icon-container">
                                <Button
                                  variant="info"
                                  className="btn-sm  text-white"
                                  onClick={() => handleEditShow(product)}
                                >
                                  <FaRegEdit title="Edit Customer" />
                                </Button>
                                <Button
                                  variant="danger"
                                  className="btn-sm"
                                  onClick={() => handleDeleteSubscription(product._id)}
                                >
                                  <FaTrashAlt title="Delete Customer" />
                                </Button>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <div className="container"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={showEditModal} onHide={handleEditClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Package</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Subscription Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter subscription name"
                value={selectedProduct?.name}
                onChange={(e) =>
                  setSelectedSubscription({
                    ...selectedProduct,
                    name: e.target.value,
                  })
                }
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Subscription Description</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter subscription description"
                value={selectedProduct?.features}
                onChange={(e) =>
                  setSelectedSubscription({
                    ...selectedProduct,
                    features: e.target.value,
                  })
                }
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Total Cost</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter total cost"
                value={selectedProduct?.price}
                onChange={(e) =>
                  setSelectedSubscription({
                    ...selectedProduct,
                    price: e.target.value,
                  })
                }
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleEditClose}>
            Close
          </Button>
          <Button variant="primary" onClick={() => handleUpdateSubscription(selectedProduct)}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default SubscriptionPackages
