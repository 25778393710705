import React from 'react'


import AddNewProject from './views/addNewProject/AddNewProject';
import Login from './views/login/Login.js';
import Dashboard from './views/dashboard/Dashboard';
import ProjectDetails from './views/projectDetails/ProjectDetails.js';
import Profile from './views/profile/Profile.js';
import CustomerList from './views/customerList/CustomerList.js';
import AddNewCustomer from './views/addNewCustomer/AddNewCustomer.js';
import ProjectList from './views/projectList/ProjectList.js';
import EditCustomer from './views/editCustomer/EditCustomer.js';
import EditProject from './views/editProject/EditProject.js';
import CustomerDetails from './views/customerDetails/CustomerDetails.js';
import Settings from './views/settings/Settings.js';
import SignUp from './views/signup/SignUp.js';
import OrderList from './views/orderList/OrderList.js';
import OrderDetails from './views/orderDetails/OrderDetails.js';
import SubscriptionPackage from './views/subscription/SubscriptionPackages.js';
import TackleMastitis from './views/tackleMastitis/TackleMastitis.js';
import MastitisNews from './views/mastitisNews/MastitisNews.js';
import MastitisHeading from './views/mastitisHeading/MastitisHeading.js';
import AboutUs from './views/aboutUs/AboutUs.js';
import Policy from './views/policyAndService/PolicyAndService.js';
import ContactMessages from './views/contactMessages/ContactMessages';
import TrainingCentre from './views/trainingCentre/TrainingCentre';
import Investor from './views/Investor/Investor';
import Calculation from './views/calculation/Calculation';
import ZoomBookings from './views/zoomBookings/ZoomBookings';
import Team from './views/teams/Team';


const routes = [
  { path: '/', exact: true, name: 'Home' },
  { path: '/login', name: 'Login', element: Login },
  { path: '/dashboard', name: 'Dashboard', element: Dashboard },
  { path: '/projectDetails/:id', name: 'ProjectDetails', element: ProjectDetails },
  { path: '/profile', name: 'Profile', element: Profile },
  { path: '/customerList', name: 'Customer List', element: CustomerList },
  { path: '/addNewCustomer', name: 'Add New Customer', element: AddNewCustomer },
  { path: '/productList', name: 'Project List', element: ProjectList },
  { path: '/addNewProduct', name: 'Add New Project', element: AddNewProject },
  { path: '/editCustomer', name: 'Edit Customer', element: EditCustomer },
  { path: '/editProduct/:id', name: 'Edit Project', element: EditProject },
  { path: '/customerDetails/:id', name: 'Customer Details', element: CustomerDetails },
  { path: '/settings', name: 'Settings', element: Settings },
  { path: '/signUp', name: 'SignUp', element: SignUp },
  { path: '/orderList', name: 'orderList', element: OrderList },
  { path: '/orderDetails/:id', name: 'orderDetails', element: OrderDetails },
  { path: '/subscription', name: 'subscription', element: SubscriptionPackage },
  { path: '/tackleMastitis', name: 'tackleMastitis', element: TackleMastitis },
  { path: '/mastitisNews', name: 'mastitisNews', element: MastitisNews },
  { path: '/mastitisHeading', name: 'mastitisHeading', element: MastitisHeading },
  { path: '/aboutUs', name: 'aboutUs', element: AboutUs },
  { path: '/policy', name: 'policy', element: Policy },
  { path: '/contact-messages', name: 'contact-messages', element: ContactMessages },
  { path: '/investor-messages', name: 'contact-messages', element: Investor },
  { path: '/training-centre', name: 'training-centre', element: TrainingCentre },
  { path: '/calculation', name: 'calculation', element: Calculation },
  { path: '/zoom', name: 'zoom', element: ZoomBookings },
  { path: '/team-management', name: 'team', element: Team },
]

export default routes
