import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Constants } from 'src/Constants'

const ProductDetails = () => {
  const { id } = useParams()
  const [product, setProduct] = useState(null)
  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await fetch(`${Constants.getProductById}${id}`)
        if (response.ok) {
          const result = await response.json()
          setProduct(result)
        } else {
          console.error('Failed to fetch product details')
        }
      } catch (error) {
        console.error('Error fetching product details:', error)
      }
    }

    fetchProduct()
  }, [id])
  // console.log(product)

  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="mb-0 text-info">PRODUCT DETAILS</h4>
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <Link to="/dashboard">Home</Link>
                    </li>
                    <li className="breadcrumb-item active text-info">Product Details</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-12">
              <div className="card custom-shadow rounded-lg border">
                <div className="card-body">
                  <h1 className="mt-4">{product?.name}</h1>
                  <p className="d-flex ">
                    <h6 className="mx-2"> Price for Normal User:  {product?.price} $ </h6>
                    <h6 className="mx-2"> Price for Licensed User:   {product?.licencedUserPrice}  $</h6>
                    <h6 className="mx-2"> Price for Farmer:   {product?.farmerPrice}  $</h6>
                    <h6 className="mx-2"> Price for Veterinarian:   {product?.veterinarianPrice}  $</h6>
                   
                    <div className="mx-auto d-flex">
                     
                      <h5 > Quantity :  {product?.quantity} </h5>
                     
                    </div>
                  </p>
                  <div className="d-flex flex-wrap">
                    {product?.imageUrls.map((imageUrl, index) => (
                      <div
                        key={index}
                        className="m-2"
                        style={{ width: 'calc(50% - 8px)', flex: '0 0 calc(50% - 8px)' }}
                      >
                        <img
                          // src={`http://localhost:9090/static/${imageUrl}`}
                          src={`https://mastitisapi.goigi.me/static/${imageUrl}`}
                          alt={`Product ${index + 1}`}
                          className="img-fluid rounded"
                          style={{ height: '150px', width: '70%' }}
                          
                        />
                      </div>
                    ))}
                  </div>
                  <p className="mt-4 ">
                   
                    <h5> Description: </h5>     <div dangerouslySetInnerHTML={{ __html: product?.description }} />
                  </p>
                  <Link to="/projectList" className="btn btn-primary">
                    Back to Products
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProductDetails
