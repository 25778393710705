import React, { useRef, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Constants } from 'src/Constants'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal)

const AddNewCustomer = () => {
  const initialFormData = {
    username: '',
    firstName: '',
    lastName: '',
    userType: 'Choose Type',
    contactNumberType: 'Choose Type',
    contactNumbers: '',
    emailAddressType: 'Choose Type',
    emailAddresses: '',
    streetAddress: '',
    city: '',
    state: '',
    country: '',
    zipCode: '',
    nameOfTheFarm:'',
    vatAndIDNumberOfFarm:'',
    role:'',
  }

  const [formData, setFormData] = useState(initialFormData)
  const [profileImage, setProfileImage] = useState(null)

  const resetForm = () => {
    setFormData(initialFormData)
    setProfileImage(null)
  }
  const [confirmPassword, setConfirmPassword] = useState('')
  const handleInputChange = (e) => {
    const { name, value } = e.target
    if (name === 'password') {
      setConfirmPassword('')
    }
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }))
  }

  const handleImageChange = (e) => {
    const file = e.target.files[0]
    setProfileImage(file)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (formData.password !== confirmPassword) {
      MySwal.fire({
        icon: 'error',
        title: 'Passwords do not match!',
      })
      return
    }

    try {
      const formDataForFile = new FormData()
      formDataForFile.append('profileImage', profileImage)

      for (const key in formData) {
        formDataForFile.append(key, formData[key])
      }

      const response = await fetch(Constants.addNewUser, {
        method: 'POST',
        body: formDataForFile,
      })

      if (response.ok) {
        MySwal.fire({
          icon: 'success',
          title: 'User added successfully!',
        })

        resetForm()
      } else {
        MySwal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
        })
      }
    } catch (error) {
      console.error('Error during API call:', error)
    }
  }
  return (
    <>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0 text-info">ADD NEW USER</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li className="breadcrumb-item active text-info">Add New User</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-12">
                <div className="card custom-shadow rounded-lg border my-2">
                  <div className="card-body">
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-lg-2">
                          <div className="form-group mb-3">
                            <label>UserName*</label>
                            <input
                              type="text"
                              className="form-control"
                              name="username"
                              value={formData?.username}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                        <div className="col-lg-5">
                          <div className="form-group mb-3">
                            <label>First Name *</label>
                            <input
                              type="text"
                              className="form-control"
                              name="firstName"
                              value={formData?.firstName}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                        <div className="col-lg-5">
                          <div className="form-group mb-3">
                            <label>Last Name *</label>
                            <input
                              type="text"
                              className="form-control"
                              name="lastName"
                              value={formData?.lastName}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>

                        <div className="col-lg-5">
                          <div className="form-group mb-3">
                            <label>Type of User *</label>
                            <select
                              className="form-control form-select"
                              name="userType"
                              value={formData?.userType}
                              onChange={handleInputChange}
                            >
                              <option>Choose Type</option>
                              <option>Farmer</option>
                              <option>Veterinarian</option>
                              <option>Licensed User</option>
                              <option>Normal User</option>
                              <option>Consultant</option>
                              <option>Processor</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-3">
                          <div className="form-group mb-3">
                            <label>Password *</label>
                            <input
                              type="password"
                              className="form-control"
                              name="password"
                              value={formData?.password}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                        <div className="col-lg-3">
                          <div className="form-group mb-3">
                            <label>Confirm Password *</label>
                            <input
                              type="password"
                              className="form-control"
                              name="confirmPassword"
                              value={confirmPassword}
                              onChange={(e) => setConfirmPassword(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group mb-3">
                            <label>Profile Image</label>
                            <input
                              type="file"
                              className="form-control"
                              accept="image/*"
                              name="profileImage"
                              onChange={handleImageChange}
                            />
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <hr />
                        </div>
                        <div className="col-lg-4">
                            <div className="form-group mb-3">
                            <label>Role</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Role"
                                name="role"
                                value={formData?.role}
                                onChange={handleInputChange}
                              />
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="form-group mb-3">
                            <label>Name of the Farm</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Name of the Farm"
                                name="nameOfTheFarm"
                                value={formData?.nameOfTheFarm}
                                onChange={handleInputChange}
                              />
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="form-group mb-3">
                              <label>VAT & ID No of the Farm</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="VAT & ID No of the Farm"
                                name="vatAndIDNumberOfFarm"
                                value={formData?.vatAndIDNumberOfFarm}
                                onChange={handleInputChange}
                              />
                            </div>
                          </div>
                        <div className="col-lg-12">
                          <hr />
                        </div>
                      </div>
                      <div>
                        <div className="col-lg-12">
                          <label>Contact Number(s) *</label>
                        </div>

                        <div className="row">
                          <div className="col-lg-2">
                            <div className="form-group mb-3">
                              <select
                                className="form-control form-select form-select-sm"
                                name="contactNumberType"
                                value={formData?.contactNumberType}
                                onChange={handleInputChange}
                              >
                                <option>Choose Type</option>
                                <option>Work</option>
                                <option>Personal</option>
                                <option>Other</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="form-group mb-3">
                              <input
                                type="number"
                                className="form-control"
                                placeholder="Contact Number"
                                name="contactNumbers"
                                value={formData?.contactNumbers}
                                onChange={handleInputChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div>
                        <div className="col-lg-12">
                          <label>Email Address(es) *</label>
                        </div>

                        <div className="row">
                          <div className="col-lg-2">
                            <div className="form-group mb-3">
                              <select
                                className="form-control form-select form-select-sm"
                                name="emailAddressType"
                                value={formData?.emailAddressType}
                                onChange={handleInputChange}
                              >
                                <option>Choose Type</option>
                                <option>Work</option>
                                <option>Personal</option>
                                <option>Other</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="form-group mb-3">
                              <input
                                type="email"
                                className="form-control"
                                name="emailAddresses"
                                value={formData?.emailAddresses}
                                onChange={handleInputChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr />

                      <div className="row">
                        <div className="col-lg-12">
                          <div className="d-flex justify-content-between">
                            <label className="fw-bold">Shipping Address</label>
                            <div></div>
                          </div>
                        </div>
                      </div>
                      <div className="addresbox">
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="form-group mb-3">
                              <label>Street Address *</label>
                              <input
                                type="text"
                                className="form-control"
                                name="streetAddress"
                                value={formData?.streetAddress}
                                onChange={handleInputChange}
                              />
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="form-group mb-3">
                              <label>City *</label>
                              <input
                                type="text"
                                className="form-control"
                                name="city"
                                value={formData?.city}
                                onChange={handleInputChange}
                              />
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="form-group mb-3">
                              <label>State *</label>
                              <input
                                type="text"
                                className="form-control"
                                name="state"
                                value={formData?.state}
                                onChange={handleInputChange}
                              />
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="form-group mb-3">
                              <label>Country *</label>
                              <input
                                type="text"
                                className="form-control"
                                name="country"
                                value={formData?.country}
                                onChange={handleInputChange}
                              />
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="form-group mb-3">
                              <label>Pin Code *</label>
                              <input
                                type="number"
                                className="form-control"
                                name="zipCode"
                                value={formData?.zipCode}
                                onChange={handleInputChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="text-end">
                        <button className="btn btn-outline-info m-2">Cancel</button>
                        <button className="btn btn-info m-2" type="submit">
                          Add Customer
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AddNewCustomer
