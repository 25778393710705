import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { AiOutlineArrowLeft } from 'react-icons/ai'
import { Card, Col, Container, Image, Row, Spinner } from 'react-bootstrap'
import { Constants } from 'src/Constants'

const CustomerDetails = () => {
  const { id } = useParams()
  const [userData, setUserData] = useState(null)
  const navigate = useNavigate()

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await fetch(Constants.getUserById + id)
        if (response.ok) {
          const data = await response.json()
          setUserData(data)
        } else {
          console.error('Failed to fetch user details')
        }
      } catch (error) {
        console.error('Error fetching user details:', error)
      }
    }

    fetchUserDetails()
  }, [id])

  if (!userData) {
   
    return <div> <Spinner animation="border" /></div>
  }

  return (
    <Container fluid>
      <Row>
        <Col xs={12}>
          <div className="d-flex align-items-center justify-content-between mb-3">
            <h4 className="mb-0 text-info">
              {' '}
              <AiOutlineArrowLeft
                onClick={() => {
                  navigate(-1)
                }}
                className="mx-1"
              />{' '}
              USER DETAILS
            </h4>
          </div>
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          <Card className="rounded-lg border-0 custom-shadow">
            <Row className="g-0">
              <Col xs={12} md={4} lg={3}>
                <Image
                  // src={`http://localhost:9090${userData?.profileImage}`}
                   src={`https://mastitisapi.goigi.me/${userData?.profileImage}`}
                  alt="User Profile"
                  className="img-fluid rounded-circle"
                  style={{ width: '250px', height: '250px', objectFit: 'cover' }}
                />
              </Col>
              <Col xs={12} md={8} lg={9}>
                <Card.Body>
                  <dl className="row">
                    <dt className="col-sm-4 col-lg-3">Name:</dt>
                    <dd className="col-sm-8 col-lg-9">
                      {userData?.firstName} {userData?.lastName}
                    </dd>
                    <dt className="col-sm-4 col-lg-3">UserName:</dt>
                    <dd className="col-sm-8 col-lg-9">{userData?.username}</dd>
                    <dt className="col-sm-4 col-lg-3">User Type:</dt>
                    <dd className="col-sm-8 col-lg-9">{userData?.userType}</dd>

                    <dt className="col-sm-4 col-lg-3">Contact Number:</dt>
                    <dd className="col-sm-8 col-lg-9">{userData?.contactNumbers}</dd>

                    <dt className="col-sm-4 col-lg-3">Email:</dt>
                    <dd className="col-sm-8 col-lg-9">{userData?.emailAddresses}</dd>
                    <dt className="col-sm-4 col-lg-3">Name of the farm:</dt>
                    <dd className="col-sm-8 col-lg-9">{userData?.nameOfTheFarm}</dd>
                    <dt className="col-sm-4 col-lg-3">VAT & ID No of the Farm :</dt>
                    <dd className="col-sm-8 col-lg-9">{userData?.vatAndIDNumberOfFarm}</dd>
                    <dt className="col-sm-4 col-lg-3">Role :</dt>
                    <dd className="col-sm-8 col-lg-9">{userData?.role}</dd>

                    <dt className="col-sm-4 col-lg-3">Shipping Address:</dt>
                    <dd className="col-sm-8 col-lg-9">
                      {userData?.streetAddress}, {userData?.city}
                    </dd>

                    <dt className="col-sm-4 col-lg-3">State:</dt>
                    <dd className="col-sm-8 col-lg-9">{userData?.state}</dd>

                    <dt className="col-sm-4 col-lg-3">Country:</dt>
                    <dd className="col-sm-8 col-lg-9">{userData?.country}</dd>

                    <dt className="col-sm-4 col-lg-3">Pin Code:</dt>
                    <dd className="col-sm-8 col-lg-9">{userData?.zipCode}</dd>
                  </dl>
                </Card.Body>
              </Col>
              <Col xs={12}>
                <Card.Body>
                  <h5 className="mb-3">Testimonials</h5>
                  {userData?.testimonials.length > 0 ? (
                    <ul>
                      {userData?.testimonials.map((testimonial) => (
                        <li key={testimonial?._id}>{testimonial?.description}</li>
                      ))}
                    </ul>
                  ) : (
                    <p>No testimonials available.</p>
                  )}
                </Card.Body>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

export default CustomerDetails
