import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { FaEye, FaRegEdit, FaTrashAlt } from 'react-icons/fa'
import Swal from 'sweetalert2'
import { AiOutlineArrowLeft, AiOutlineArrowRight } from 'react-icons/ai'
import withReactContent from 'sweetalert2-react-content'
import { Container, Row, Col, Table, Button, InputGroup, FormControl } from 'react-bootstrap'
import { Constants } from 'src/Constants'

const MySwal = withReactContent(Swal)

const ProjectList = () => {
  const [projectData, setProjectData] = useState([])
  const [searchInput, setSearchInput] = useState('')
  const [sortColumn, setSortColumn] = useState(null)
  const [sortOrder, setSortOrder] = useState('asc')
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage] = useState(5)

  useEffect(() => {
    // Fetch data from the API
    const fetchProjects = async () => {
      try {
        const response = await fetch(Constants.getAllProduct)
        if (!response.ok) {
          throw new Error('Failed to fetch data')
        }

        const data = await response.json()
        setProjectData(data)
      } catch (error) {
        console.error('Error fetching data:', error)
        // Handle error, show an alert, etc.
      }
    }

    // Call the fetchProjects function
    fetchProjects()
  }, [])
  const handleDelete = async (productId) => {
    try {
      // Display a confirmation dialog
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      })

      if (result.isConfirmed) {
        // User confirmed, proceed with deletion
        const response = await fetch(Constants.deleteProduct + productId, {
          method: 'DELETE',
        })

        if (response.ok) {
          // Filter out the deleted product from the state
          setProjectData((prevData) => prevData.filter((product) => product._id !== productId))
          Swal.fire({
            icon: 'success',
            title: 'Product deleted successfully',
          })
        } else {
          throw new Error('Failed to delete product')
        }
      }
    } catch (error) {
      console.error('Error deleting product:', error)
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'An error occurred while deleting the product',
      })
    }
  }
  const truncateDescription = (description, limit) => {
    if (!description) return ''

    const plainText = description.replace(/<[^>]*>/g, '')
    if (plainText.length <= limit) {
      return description
    }

    return `${plainText.substring(0, limit)}...`
  }
  const handleDownloadCSV = () => {
    // Convert projectData to CSV string
    const csvContent = convertToCSV(projectData)

    // Create a Blob containing the CSV data
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })

    // Create a temporary anchor element to trigger the download
    const link = document.createElement('a')
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob)
      link.setAttribute('href', url)
      link.setAttribute('download', 'product_data.csv')
      link.style.visibility = 'hidden'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }

  const convertToCSV = (data) => {
    // Header row
    const header = Object.keys(data[0]).join(',')

    // Data rows
    const rows = data.map((item) => Object.values(item).join(','))

    return `${header}\n${rows.join('\n')}`
  }

  const filterProducts = (products, query) => {
    return products.filter((product) => product?.name.toLowerCase().includes(query.toLowerCase()))
  }

  const handleSearchInputChange = (event) => {
    setSearchInput(event.target.value)
  }

  const handleSort = (column) => {
    if (sortColumn === column) {
      setSortOrder((prevOrder) => (prevOrder === 'asc' ? 'desc' : 'asc'))
    } else {
      setSortColumn(column)
      setSortOrder('asc')
    }
  }

  const filteredAndSortedProjectData = projectData
    .slice() // Create a copy to avoid mutating the original array
    .filter((product) => product?.name.toLowerCase().includes(searchInput.toLowerCase()))
    .sort((a, b) => {
      const columnA = a[sortColumn]
      const columnB = b[sortColumn]

      if (columnA && columnB) {
        const valueA = typeof columnA === 'string' ? columnA : String(columnA)
        const valueB = typeof columnB === 'string' ? columnB : String(columnB)

        if (sortOrder === 'asc') {
          return valueA.localeCompare(valueB)
        } else {
          return valueB.localeCompare(valueA)
        }
      }

      return 0
    })
  const indexOfLastItem = currentPage * itemsPerPage
  const indexOfFirstItem = indexOfLastItem - itemsPerPage
  const currentItems = filteredAndSortedProjectData.slice(indexOfFirstItem, indexOfLastItem)
  const paginate = (pageNumber) => setCurrentPage(pageNumber)

  return (
    <div className="main-content">
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="mb-0 text-info">PRODUCT LIST</h4>
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <Link to="/dashboard">Home</Link>
                    </li>
                    <li className="breadcrumb-item active text-info">Product List</li>
                  </ol>
                </div>
              </div>
            </Col>
          </Row>

          <Row className="m-2">
            <Col xs={12}>
              <div className="card custom-shadow rounded-lg border">
                <div className="card-body">
                  <Row>
                    <Col md={4} xs={12} className="mb-3 text-center">
                      <h3 className="fw-bolder text-info mb-0"> {projectData?.length} </h3>
                      <p className="text-secondary">Total Products</p>
                    </Col>
                  </Row>
                  <div>
                    <div className="dataTables_length mb-2">
                      {/* <div className="search-box">
                        <input
                          type="text"
                          id="search"
                          placeholder="Search Products"
                          name="search"
                          className="form-control "
                          value={searchInput}
                          onChange={handleSearchInputChange}
                        />
                      </div> */}
                    </div>

                    <Table responsive bordered hover>
                      <thead>
                        <tr>
                          <th onClick={() => handleSort('name')}>
                            Product Name
                            {sortColumn === 'name' && (
                              <span>{sortOrder === 'asc' ? ' 🔽' : ' 🔼'}</span>
                            )}
                          </th>
                          <th onClick={() => handleSort('description')}>
                            Description
                            {sortColumn === 'description' && (
                              <span> {sortOrder === 'asc' ? ' 🔽' : ' 🔼'}</span>
                            )}
                          </th>
                          <th onClick={() => handleSort('price')}>
                            Normal User Price
                            {sortColumn === 'price' && (
                              <span> {sortOrder === 'asc' ? ' 🔽' : ' 🔼'}</span>
                            )}
                          </th>
                          <th onClick={() => handleSort('licensedUserPrice')}>
                            Licenced User Price
                            {sortColumn === 'licensedUserPrice' && (
                              <span>{sortOrder === 'asc' ? ' 🔽' : ' 🔼'}</span>
                            )}
                          </th>
                          <th onClick={() => handleSort('farmerPrice')}>
                            Farmer Price
                            {sortColumn === 'farmerPrice' && (
                              <span>{sortOrder === 'asc' ? ' 🔽' : ' 🔼'}</span>
                            )}
                          </th>
                          <th onClick={() => handleSort('veterinarianPrice')}>
                            Veterinarian Price
                            {sortColumn === 'veterinarianPrice' && (
                              <span>{sortOrder === 'asc' ? ' 🔽' : ' 🔼'}</span>
                            )}
                          </th>

                          <th onClick={() => handleSort('quantity')}>
                            Quantity
                            {sortColumn === 'quantity' && (
                              <span>{sortOrder === 'asc' ? ' 🔽' : ' 🔼'}</span>
                            )}
                          </th>
                          <th>Actions</th>
                        </tr>
                      </thead>

                      <tbody>
                        {currentItems.map((product, index) => (
                          <tr key={product?._id}>
                            <td>{product?.name}</td>
                            <td
                              dangerouslySetInnerHTML={{
                                __html: truncateDescription(product?.description, 5),
                              }}
                            />
                            <td>{product?.price} $</td>
                            <td>{product?.licencedUserPrice} $</td>
                            <td>{product?.farmerPrice} $</td>
                            <td>{product?.veterinarianPrice} $</td>

                            <td>
                              {product?.quantityForPackages !== null
                                ? `${product.quantityForPackages} pcs`
                                : `${product.quantityForTwentyLbarrel} ltr`}
                            </td>
                            <td>
                              <div className="icon-container">
                                {/* <Link
                                  className="btn btn-sm btn-info text-white"
                                  to={`/projectDetails/${product?._id}`}
                                >
                                  <FaEye title="View Product" />
                                </Link> */}
                                <Link
                                  className="btn btn-sm btn-info text-white"
                                  to={`/editProduct/${product?._id}`}
                                >
                                  <FaRegEdit title="Edit Product" />
                                </Link>
                                <Button
                                  variant="danger"
                                  className="btn-sm"
                                  onClick={() => handleDelete(product?._id)}
                                >
                                  <FaTrashAlt title="Delete Product" />
                                </Button>
                                <Button
                                  variant="primary"
                                  className="btn-sm"
                                  title="Download CSV"
                                  onClick={handleDownloadCSV}
                                  disabled={!projectData?.length}
                                >
                                  Download CSV
                                </Button>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    {/* Pagination controls */}
                    <div className="d-flex justify-content-center">
                      <ul className="pagination">
                        {Array.from(
                          { length: Math.ceil(filteredAndSortedProjectData.length / itemsPerPage) },
                          (_, i) => (
                            <li
                              key={i}
                              className={`page-item ${currentPage === i + 1 ? 'active' : ''}`}
                            >
                              <button onClick={() => paginate(i + 1)} className="page-link">
                                {i + 1}
                              </button>
                            </li>
                          ),
                        )}
                      </ul>
                    </div>
                    <Container></Container>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}

export default ProjectList
